export type CountryCode = {
    code: string;
    dialCode: number;
    label: string;
}

export const countryCodes: CountryCode[] = [
    {code: "mx", dialCode: 521, label: "+52"},
    {code: "co", dialCode: 57, label: "+57"},
];

export const getCountry = (countryCode: string): CountryCode => countryCodes.filter((item) => item.code === countryCode)[0];

export const formatPhone = (phone: string, countryCode: string = "mx"): string => {
    const { dialCode } = getCountry(countryCode);

    if (phone.length === 10) return `${dialCode}${phone}`;
    return phone;
};

export const unformatPhone = (phone: string): string => {
    let { dialCode } = getCountry("mx");

    if (phone.startsWith("57")) {
        dialCode = getCountry("co").dialCode;
    }

    return phone.replace(dialCode.toString(), "");
};
