import _ from "lodash";
import { LeadFormFieldsEnum, LeadStepEnum } from "models/lead/enums/lead.enums";
import { Lead } from "models/lead/interfaces/lead.interface";
import { initLead, setNextStep, IStepperFormValues } from "../context/context";

export const LeadtoFormValues = (newLead: Lead): IStepperFormValues => {
    const lead = {
        ...initLead,
        ...newLead
    };

    return {
        [LeadStepEnum.emailStep]: {
            email: lead.email,
            privacyPolicyAcceptance: lead.privacyPolicyAcceptance,
            termsOfServiceAcceptance: lead.termsOfServiceAcceptance,
        },
        [LeadStepEnum.personalInformationStep]: {
            name: lead.name,
            lastName: lead.lastName,
            curp: lead.curp
        },
        [LeadStepEnum.addressStep]: {
            fullAddress: lead.personalInformation?.fullAddress
        },
        [LeadStepEnum.phoneStep] : {
            phone: lead.personalInformation?.phone
        },
        [LeadStepEnum.phoneValidationStep] : {
            moffin: lead.moffin
        },
        [LeadStepEnum.financialInformationStep]: {
            monthlyIncome: lead.financialInformation?.monthlyIncome,
            monthlyOutcome: lead.financialInformation?.monthlyOutcome,
        },
        [LeadStepEnum.dependantsStep]: {
            dependantsCount: lead.personalInformation?.dependantsCount,
            childrenCount: lead.personalInformation?.childrenCount,
        },
        [LeadStepEnum.employmentAndEducationStep]: {
            workInDigitalPlatforms: lead.financialInformation?.workInDigitalPlatforms,
            economicActivity: lead.financialInformation?.economicActivity,
            companyName: lead.financialInformation?.companyName,
            educationalLevel: lead.personalInformation?.educationalLevel
        },
        [LeadStepEnum.civilStatusStep]: {
            civilStatus: lead.personalInformation?.civilStatus,
            livesWith: lead.personalInformation?.livesWith
        },
        [LeadStepEnum.assetsStep]: {
            assets: lead.financialInformation?.assets
        },
    };
};

export const FormValuesToLead = (values: IStepperFormValues, lead: Lead): Lead => {
    const formatedLead = {
        ...lead,
        email: _.get(values, LeadFormFieldsEnum.EMAIL, "").toLowerCase(),
        privacyPolicyAcceptance: _.get(values, LeadFormFieldsEnum.PRIVACY_POLICY_ACCEPTANCE),
        termsOfServiceAcceptance: _.get(values, LeadFormFieldsEnum.TERMS_OF_SERVICE_ACCEPTANCE),
        name: _.get(values, LeadFormFieldsEnum.NAME),
        lastName: _.get(values, LeadFormFieldsEnum.LAST_NAME),
        curp: _.get(values, LeadFormFieldsEnum.CURP),
        personalInformation: {
            ...(
                _.get(values, LeadFormFieldsEnum.FULL_ADDRESS) ? {
                    fullAddress: {
                        zipCode: _.get(values, LeadFormFieldsEnum.ZIP_CODE),
                        state: _.get(values, LeadFormFieldsEnum.STATE),
                        stateCode: _.get(values, LeadFormFieldsEnum.STATE_CODE),
                        city: _.get(values, LeadFormFieldsEnum.CITY),
                        delegation: _.get(values, LeadFormFieldsEnum.DELEGATION),
                        neighborhood: _.get(values, LeadFormFieldsEnum.NEIGHBORHOOD),
                        street: _.get(values, LeadFormFieldsEnum.STREET),
                        intNumber: _.get(values, LeadFormFieldsEnum.INT_NUMBER),
                        extNumber: _.get(values, LeadFormFieldsEnum.EXT_NUMBER),
                        lat: _.get(values, LeadFormFieldsEnum.LAT),
                        long: _.get(values, LeadFormFieldsEnum.LONG),
                    }
                } : {}
            ),
            phone: _.get(values, LeadFormFieldsEnum.PHONE),
            dependantsCount: _.get(values, LeadFormFieldsEnum.DEPENDANTS),
            childrenCount: _.get(values, LeadFormFieldsEnum.CHILDREN),
            civilStatus: _.get(values, LeadFormFieldsEnum.CIVIL_STATUS),
            educationalLevel: _.get(values, LeadFormFieldsEnum.EDUCATION_LEVEL),
            livesWith: _.get(values, LeadFormFieldsEnum.LIVES_WITH),
            personalReferences: undefined,
            documents: []
        },
        financialInformation: {
            monthlyIncome: _.get(values, LeadFormFieldsEnum.MONTHLY_INCOME),
            monthlyOutcome: _.get(values, LeadFormFieldsEnum.MONTHLY_OUTCOME),
            companyName: _.get(values, LeadFormFieldsEnum.COMPANY_NAME),
            ...(
                _.get(values, LeadFormFieldsEnum.ECONOMIC_ACTIVITY) ? {
                    economicActivity: {
                        value: _.get(values, LeadFormFieldsEnum.ECONOMIC_ACTIVITY_VALUE),
                        description: _.get(values, LeadFormFieldsEnum.ECONOMIC_ACTIVITY_DESCRIPTION),
                    },
                } : {}
            ),
            workInDigitalPlatforms: _.get(values, LeadFormFieldsEnum.WORK_IN_DIGITAL_PLATFORMS),
            assets: _.get(values, LeadFormFieldsEnum.ASSETS),
        },
    } as unknown as Lead;


    formatedLead.step = setNextStep(formatedLead.step as LeadStepEnum) ?? formatedLead.step;
    return formatedLead;
};
