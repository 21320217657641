/* eslint-disable no-restricted-globals */
import React, { ChangeEvent, useCallback, useEffect, useState, useMemo } from "react";
import {
  Button,
  Container,
  Grid,
  Skeleton,
  TextField,
  useMediaQuery,
  Slider,
} from "@mui/material";
import {
  Modal,
  Typography,
  Button as Btn,
  Input,
  BreadCrumb,
} from "design_system/src";
import Tippy from "@tippyjs/react";
import { ReactComponent as Location } from "design_system/src/static/icons/map.svg";
import { ReactComponent as AddCircle } from "design_system/src/static/icons/add-circle.svg";
import Swal from "sweetalert2";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ChevronRight } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { VehicleStatusEnum } from "models/inventory/enums/vehicle.enums";
import { getVehicle } from "models/inventory/controllers/vehicle.controller";
import { debounce } from "lodash";
import VehicleCarousel from "../../components/vehicleCarousel/VehicleCarousel";
import { TImages, TVehicle } from "../../models/inventory/interfaces/vehicle.interface";
import LogoMini from "../../static/icons/logoMini.png";
import PlanSelectionBox from "../../components/planSelectionBox/PlanSelectionBox";
import TextContainer from "../../components/textContainer/TextContainer";
import { HubsSection } from "../../components/hocs/bannerMX/hubs/HubsSection";
import RecommendedDetails from "./RecommendedDetails/RecommendedDetails";
import PaymentButtons from "../../components/paymentButtons/PaymentButtons";
import { getColorLabelEs } from "../../helpers/translateColor";
import { formatPrice } from "../../helpers/formatPrice";
import { priceFormatFinancial, prices } from "../../helpers/prices";
import {
  setApplicationLoanTime,
  setApplicationVehicle,
} from "../../store/actions/application";
import { ContactHelper } from "../../components/hocs/ContactHelper/ContactHelper";

export const Divider = () => {
  return (
    <div
      style={{
        borderLeft: "1px solid #D7D7D7",
        height: "15px",
        paddingLeft: "10px",
        marginLeft: "10px",
      }}
    />
  );
};

interface ISelectPlanArr {
  title: string;
  weeks: number;
  weeklyAmount: string;
  noDiscountAmount?: string;
}

const titles: any = {
  "52": "Facilidad",
  "78": "Oportunidad",
  "104": "Comodidad",
  "156": "Seguridad",
};

type DescriptionRowProps = {
  label: string;
  description: string | undefined;
};

export const DescriptionRow: React.FC<DescriptionRowProps> = ({
  label,
  description,
}) => {
  // let truncatedText = description;
  // if(description && description.length > 15){
  //    truncatedText = description!.slice(0, 15);
  //   truncatedText = `${truncatedText}...`;
  // }0.81852px solid #ECEEEF

  return (
    <Grid
      container
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
      style={{
        border: "0.33px solid #ECEEEF",
        borderRadius: 3,
      }}
    >
      <Grid item xs={5} style={{ backgroundColor: "#F4F5F6", padding: 6 }}>
        <Typography
          weight="600"
          scale="small"
          className=" m_l_md"
          textColor="neutral_700"
        >
          {label}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography weight="400" scale="small" className=" m_l_lg">
          {description || "No aplica"}
        </Typography>
      </Grid>
    </Grid>
  );
};

type paramDetail = {
  id: string;
};

const NewDetailsView = () => {
  // MODAL ADVANCED MONEY
  const [deposit, setDeposit] = useState<number>(0);
  const [initialPaymentAmount, setInitialPaymentAmount] = useState<number>(0);
  const [advancedMoney, setAdvancedMoney] = useState<number>(0);
  const [OpenAM, setOpenAM] = useState<boolean>(false);
  const [Opened, setOpened] = useState<boolean>(false);
  const min = 0;
  const max = 20000;
  const step = 500;

  const handleInputAMChange = useCallback(
    debounce((valuetosaved: number) => {
      if (valuetosaved >= min && valuetosaved <= max) {
        setInitialPaymentAmount(valuetosaved);
      }
    }, 300), // 300ms de retraso
    []
  );

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setAdvancedMoney(newValue as number);
    handleInputAMChange(newValue as number);
  };

  const handleButtonSum = () => {
    if (advancedMoney >= min && advancedMoney < max) {
      setAdvancedMoney(advancedMoney + 500);
      handleInputAMChange(advancedMoney + 500);
    }
  };

  const handleButtonRest = () => {
    if (advancedMoney > min && advancedMoney <= max) {
      setAdvancedMoney(advancedMoney - 500);
      handleInputAMChange(advancedMoney - 500);
    }
  };

  const onInputChange  = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valuetosaved = Number(event.target.value);
    setAdvancedMoney(valuetosaved);
    if (valuetosaved < min || valuetosaved > max) {
      return;
    }
    handleInputAMChange(valuetosaved);
  };

  const BoxShadow =
    "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

  const handleModalAM = () => {
    setOpenAM(!OpenAM);
  };

  const handleButtonAM = () => {
    if (!Opened) {
      setOpenAM(!OpenAM);
    }
    setOpened(true);
  };

  const matchesXS = useMediaQuery("(min-width:600px)");

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [vehicle, setvehicle] = useState<TVehicle>();
  const [loading, setloading] = useState(true);
  const [photos, setPhotos] = useState<TImages[]>([]);

  const [selectedPlanIndex, setSelectedPlanIndex] = useState<number>(0);
  const [selectPlanArr, setSelectPlanArr] = useState<ISelectPlanArr[]>([]);
  const selectedWeeks = useMemo<number>(() => {
    return selectPlanArr[selectedPlanIndex]?.weeks ?? 52;
  }, [selectedPlanIndex, selectPlanArr]);

  const [currentPayingAmount, setCurrentPayingAmount] = useState(0);

  const [formValue, setFormValue] = useState(0);
  const [hasError, setHasError] = useState(false);

  const [selectedButton, setSelectedButton] = useState<string>("0");

  const maxVehiclePercentage = 0.8;

  // @ts-ignore
  const { weeks: selectedWeeksProps } = location?.state || {};

  const planSwitch = (weeks: number) => {
    switch (weeks) {
      case 52:
        return 0;
      case 78:
        return 1;
      case 104:
        return 2;
      default:
        return 0;
    }
  };
  useEffect(() => {
    if (selectedWeeksProps) {
      const index = selectPlanArr.findIndex(({ weeks }) => weeks === selectedWeeksProps);
      setSelectedPlanIndex(index >= 0 ? index : 0);
    }
  }, []);

  const handleInputChange = (event: any) => {
    setFormValue(event.target.value);
  };

  const handleInputSubmit = (event: any) => {
    event.preventDefault();

    if (
      formValue >
      (vehicle?.getWeeklyPrice(selectedWeeks) || 1) * selectedWeeks
    ) {
      setHasError(true);
      return;
    }

    setHasError(false);
    setInitialPaymentAmount(formValue);
  };

  const [open, setopen] = useState<boolean>(false);

  const openFinancialForm = () => {
    if (vehicle) {
      dispatch(setApplicationVehicle(vehicle));
      dispatch(setApplicationLoanTime(selectedWeeks));
      navigate("/financia-tu-moto");
    }
  };

  const calculateCurrentAmount = (week: number) => {
    const currentPrice = (vehicle?.getWeeklyPrice(week) || 1) * week;
    const amountDifference = currentPrice - initialPaymentAmount;
    return Math.round(amountDifference / week);
  };

  useEffect(() => {
    if (vehicle) {
      vehicle.setAdvancedMoney(Number(initialPaymentAmount));
      dispatch(setApplicationVehicle(vehicle));
      dispatch(setApplicationLoanTime(selectedWeeks));
      const newAmount = calculateCurrentAmount(selectedWeeks);
      setCurrentPayingAmount(newAmount);
    }
  }, [initialPaymentAmount]);

  useEffect(() => {
    if (formValue !== 0) {
      setSelectedButton("");
    }
  }, [formValue]);

  useEffect(() => {
    (async () => {
      if(!id) return;

      const res = await getVehicle(id);
      if (res) {
        setvehicle(res);
        if (res.images) {
          setPhotos(res.images);
        }
        setloading(false);
      } else {
        Swal.fire({
          title: "Error",
          text: "El vehículo no existe",
          icon: "error",
          confirmButtonText: "Aceptar",
        }).then(() => {
          navigate("/");
        });
      }
    })();
  }, [id]);

  useEffect(() => {
    if (vehicle && vehicle.status !== VehicleStatusEnum.AVAILABLE) {
      navigate("/vehiculo-no-disponible",{
        state: { model: vehicle.internalId },
      });
      if (selectedWeeksProps) {
        const index = selectPlanArr.findIndex(({ weeks }) => weeks === selectedWeeksProps);
        setSelectedPlanIndex(index >= 0 ? index : 0);
      } else {
        setSelectedPlanIndex(vehicle.salePrices.length - 1);
      }
    }
  }, [vehicle]);

  const updateSelectPlanArr = async () => {
    const salePrices = vehicle?.salePrices ?? [];
    const PlanArr = [];
    // eslint-disable-next-line no-plusplus
    for (let i=0; i<salePrices.length; i++) {
      const { weeks } = salePrices[i];
      const title = titles[weeks];
      const quotaValue = initialPaymentAmount > 0
        // eslint-disable-next-line no-await-in-loop
        ? await vehicle?.getQuotaValue(weeks, initialPaymentAmount)
        : vehicle?.getWeeklyPrice(weeks);
      const weeklyAmount = priceFormatFinancial(prices(quotaValue), 0);
      const valueBeforeDiscount = vehicle?.getWeeklyPriceWithoutDiscount(weeks) ?? 0;
      PlanArr.push({
        title,
        weeks,
        weeklyAmount,
        noDiscountAmount: valueBeforeDiscount > 0 ? valueBeforeDiscount.toString() : undefined,
      });
    }

    setSelectPlanArr(PlanArr);
  };

  useEffect(() => {
    if (vehicle && vehicle.status !== VehicleStatusEnum.AVAILABLE) {
      navigate("/vehiculo-no-disponible",{
        state: { model: vehicle.internalId },
      });
    }
    updateSelectPlanArr();

  }, [vehicle, initialPaymentAmount]);

  const handlePlanClick = (index: number, weeks: number = 52) => {
    setSelectedPlanIndex(index);
  };

  useEffect(() => {
    const amountTotal = calculateCurrentAmount(selectedWeeks) * selectedWeeks;
    if (amountTotal > 60000) {
      setDeposit(3000);
    } else if (amountTotal > 45000 && amountTotal <= 60000) {
      setDeposit(2500);
    } else {
      setDeposit(2000);
    }
  }, [selectedWeeks, vehicle]);

  const tooltipContent = () => (
    <div className="display_flex flex_gap_xxs p_sm">
      <Typography
        scale="small"
        weight="400"
      >
        Este monto de dinero se abona junto con la primera cuota
        y funciona como una garantía. Una vez que se ha pagado
        la totalidad del crédito, se devuelve.
      </Typography>
    </div>
  );

  return (
    <>
      <Container
        maxWidth="xl"
        style={{ padding: !matchesXS ? "0" : "inherit" }}
      >
        <Grid
          sx={{ paddingTop: !matchesXS ? "0px" : "3vh" }}
          container
          justifyContent="center"
          columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3, xl: 3 }}
          rowSpacing={2}
        >
          <Grid item xs={12} sm={12} md={6}>
            <div>
              <BreadCrumb url={location.pathname} />
            </div>

            <div
              className={!matchesXS ? "" : ""}
              style={{
                borderRadius: !matchesXS ? "" : "10px",
                padding: !matchesXS ? "" : "15px",
              }}
            >
              <VehicleCarousel photos={photos} />
            </div>
            <br />
            <br />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            className=""
            style={{ padding: !matchesXS ? "0px 0px" : "inherit" }}
          >
            <div
              id="portal"
              className="display_none_mobile"
              style={{
                zIndex: 5,
                position: "absolute",
                backgroundColor: "white",
                marginTop: "10vh",
              }}
            />

            <div
              className="p_x_md "
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <img height="26" src={LogoMini} alt="mini" />
              <Typography scale="large" weight="600" className="m_l_md">
                {`${vehicle?.brand.name || "Cargando..."} ${
                  vehicle?.model.name || ""
                } ${vehicle?.cylindersCapacity || ""}${
                  vehicle?.suffix ? vehicle.suffix : ""
                }`}
              </Typography>
            </div>

            <div
              className="m_t_sm p_x_md"
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Typography scale="medium" weight="600" className="">
                {vehicle?.year || ""}
              </Typography>
              <Divider />
              <Typography scale="medium" weight="600" className="">
                {`${vehicle?.cylindersCapacity || ""} CC`}
              </Typography>
              <Divider />
              <Typography scale="medium" weight="600" className="">
                {vehicle?.mileage === 0 && !vehicle?.isNewVehicle
                  ? "KM pendiente"
                  : `${vehicle?.mileage} Km`}
              </Typography>
              <Divider />
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <div style={{ paddingTop: "3px" }}>
                  <Location style={{ height: "15px" }} />
                </div>
                <Typography scale="medium" weight="600" className="">
                  {vehicle?.location.hub.name}
                </Typography>
              </div>
            </div>
            <div className="p_x_md m_b_xl">
              <Typography scale="small" weight="400" className="m_t_sm">
                {vehicle?.shortDescription || ""}
              </Typography>
            </div>

            <div className="dso_card m_x_md m_y_xxl p_xl">
              {!Opened ? (
                <Typography
                  scale="medium"
                  weight="600"
                  className="m_y_xs"
                >
                  Selecciona tu forma de pago:
                </Typography>
              ) : (
                <div className="display_flex flex_col flex_gap_xs">
                  <div className="display_flex flex_align_center flex_justify_between_desktop flex_gap_xs_mobile flex_col_mobile">
                    <Typography
                      scale="small"
                      weight="400"
                      className="m_y_xs text_center_mobile"
                    >
                      ¿Quieres dar{" "}
                      <span style={{ fontWeight: "bold" }}>
                        un pago inicial?
                      </span>{" "}
                      <span className="text_neutral_500">(opcional)</span>
                    </Typography>
                    <div className="w_100_per_mobile w">
                      <Input
                        type="number"
                        style={{ textAlign: "center" }}
                        title=""
                        placeholder=""
                        name="input-slider"
                        onChange={onInputChange}
                        value={advancedMoney === 0 ? "" : advancedMoney}
                      />
                    </div>
                  </div>
                  <div className="bg_neutral_200 br_xxs p_md display_flex flex_gap_md_desktop flex_gap_xl_mobile flex_center m_b_md_mobile">
                    <div
                      className="display_flex flex_center bg_neutral_0 text_primary_300 br_xxs  cursor_pointer dim_25_px"
                      onClick={handleButtonRest}
                      style={{
                        WebkitUserSelect: "none",
                        MozUserSelect: "none",
                        msUserSelect: "none",
                        userSelect: "none",
                      }}
                    >
                      -
                    </div>
                    <div className="w_70_per_desktop w_60_per_mobile">
                      <Slider
                        valueLabelDisplay="auto"
                        value={advancedMoney}
                        onChange={handleSliderChange}
                        aria-labelledby="slider"
                        min={min}
                        max={max}
                        step={step}
                        sx={{
                          color: "rgb(248,157,62) !important",
                          height: "10",
                          padding: "15px 0",
                          "& .MuiSlider-thumb": {
                            height: 18,
                            width: 18,
                            backgroundColor: "rgb(245,118,10) ",
                            boxShadow: BoxShadow,
                            "&:focus, &:hover, &.Mui-active": {
                              boxShadow:
                                "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
                              // Reset on touch devices, it doesn't add specificity
                              "@media (hover: none)": {
                                boxShadow: BoxShadow,
                              },
                            },
                          },

                          "& .MuiSlider-track": {
                            border: "none",
                            height: 8,
                          },
                          "& .MuiSlider-rail": {
                            opacity: 0.5,
                            height: 8,
                            backgroundColor: "white",
                            border: "1px solid #C7CCD1",
                            borderRadius: 8,
                          },
                          "& .MuiSlider-markLabel": {
                            marginTop: 7,
                            "&.MuiSlider-markLabelActive": {
                              opacity: 1,
                              color: "rgba(245,118,10,0.9)",
                            },
                          },
                          "& .MuiSlider-mark": {
                            backgroundColor: "#bfbfbf",
                            height: 8,
                            width: 2,
                            marginTop: 12,
                            "&.MuiSlider-markActive": {
                              opacity: 1,
                              backgroundColor: "rgb(245,118,10)",
                            },
                          },
                        }}
                      />
                    </div>
                    <div
                      className="display_flex flex_center bg_neutral_0 text_primary_300 br_xxs  cursor_pointer dim_25_px"
                      onClick={handleButtonSum}
                      style={{
                        WebkitUserSelect: "none",
                        MozUserSelect: "none",
                        msUserSelect: "none",
                        userSelect: "none",
                      }}
                    >
                      +
                    </div>
                  </div>
                </div>
              )}

              {selectPlanArr?.map((item, idx) => (
                <PlanSelectionBox
                  title={item.title}
                  key={`${item.weeks}-${idx}`}
                  weeks={item.weeks}
                  weeklyAmount={item.weeklyAmount}
                  handlePlanClick={handlePlanClick}
                  index={idx}
                  selectedPlanIndex={selectedPlanIndex}
                  noDiscountAmount={item.noDiscountAmount}
                />
              ))}

              {!Opened && (
                <div
                  style={{
                    WebkitUserSelect: "none",
                    MozUserSelect: "none",
                    msUserSelect: "none",
                    userSelect: "none",
                  }}
                >
                  <Typography
                    scale="small"
                    weight="600"
                    className="m_t_xl m_b_xs"
                  >
                    Haz que tu plan sea más barato
                  </Typography>
                  <div
                    className="bg_neutral_200 display_flex flex_gap_xs p_md br_xs cursor_pointer"
                    onClick={handleButtonAM}
                  >
                    <AddCircle className="text_neutral_1000" />
                    <Typography scale="small" weight="600" className="">
                      Agregar pago inicial
                    </Typography>
                    <Typography
                      scale="small"
                      weight="600"
                      className="text_neutral_600"
                    >
                      (Opcional)
                    </Typography>
                  </div>
                  <Typography
                    scale="medium"
                    weight="600"
                    className="m_t_md m_b_xs"
                  >
                    Resumen Plan {selectPlanArr?.[selectedPlanIndex!]?.title}
                  </Typography>
                  <div
                    className="bg_green_100 display_flex flex_col flex_gap_md p_md br_xs m_t_md"
                  >
                    <div className="display_flex flex_align_center flex_justify_between">
                      <div className="display_flex flex_align_center">
                        <Typography scale="medium" weight="400">
                          Depósito en garantía
                        </Typography>
                        <Tippy content={tooltipContent()} className="bg_neutral_0 shadow_hard">
                          <div className="border_solid border_1 border_neutral_700 br_circle dim_md cursor_pointer pos_relative m_l_xs">
                            <Typography
                              weight="600"
                              scale="xxsmall"
                              textColor="neutral_700"
                              className=" pos_absolute center"
                            >
                              i
                            </Typography>
                          </div>
                        </Tippy>
                      </div>
                      <Typography
                        scale="medium"
                        weight="400"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        ${deposit.toLocaleString("en-US")} MXN
                      </Typography>
                    </div>
                    <div className="display_flex flex_align_center flex_justify_between">
                      <Typography scale="medium" weight="400">
                        Pago semanal
                      </Typography>
                      <Typography
                        scale="medium"
                        weight="400"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        ${calculateCurrentAmount(selectedWeeks).toLocaleString("en-US")} MXN
                      </Typography>
                    </div>
                    <div className="display_flex flex_align_center flex_justify_between">
                      <Typography scale="large" scaleMobile="medium" weight="600" className="">
                        TOTAL (Primer pago)
                      </Typography>
                      <Typography
                        scale="large"
                        scaleMobile="medium"
                        weight="600"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        ${(calculateCurrentAmount(selectedWeeks) + deposit).toLocaleString("en-US")} MXN
                      </Typography>
                    </div>
                  </div>
                </div>
              )}

              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: !matchesXS ? "" : "flex-end",
                  }}
                >
                  <Button
                    disableElevation
                    className="m_y_md w_100_per"
                    variant="contained"
                    endIcon={<ChevronRight />}
                    style={{
                      backgroundColor: "#FE8A02",
                      color: "white",
                      fontSize: "14px",
                    }}
                    onClick={openFinancialForm}
                  >
                    Seleccionar y continuar
                  </Button>
                </div>
              </div>
              <Modal open={OpenAM} setOpen={handleModalAM} className="modalAM">
                <div className="display_flex flex_col flex_gap_xs">
                  <div className="display_flex flex_align_center flex_justify_between_desktop flex_gap_xs_mobile flex_col_mobile">
                    <Typography
                      scale="small"
                      weight="400"
                      className="m_y_xs text_center_mobile"
                    >
                      ¿Quieres dar{" "}
                      <span style={{ fontWeight: "bold" }}>
                        un pago inicial?
                      </span>{" "}
                      <span className="text_neutral_500">(opcional)</span>
                    </Typography>
                    <div className="w_100_per_mobile w">
                      <Input
                        type="number"
                        style={{ textAlign: "center" }}
                        title=""
                        placeholder=""
                        name="input-slider"
                        onChange={onInputChange}
                        value={advancedMoney === 0 ? "" : advancedMoney}
                      />
                    </div>
                  </div>
                  <div className="bg_neutral_200 br_xxs p_md display_flex flex_gap_md_desktop flex_gap_xl_mobile flex_center">
                    <div
                      className="display_flex flex_center bg_neutral_0 text_primary_300 br_xxs  cursor_pointer dim_25_px"
                      onClick={handleButtonRest}
                      style={{
                        WebkitUserSelect: "none",
                        MozUserSelect: "none",
                        msUserSelect: "none",
                        userSelect: "none",
                      }}
                    >
                      -
                    </div>
                    <div className="w_70_per_desktop w_60_per_mobile">
                      <Slider
                        valueLabelDisplay="auto"
                        value={advancedMoney}
                        onChange={handleSliderChange}
                        aria-labelledby="slider"
                        min={min}
                        max={max}
                        step={step}
                        sx={{
                          color: "rgb(248,157,62) !important",
                          height: "10",
                          padding: "15px 0",
                          "& .MuiSlider-thumb": {
                            height: 18,
                            width: 18,
                            backgroundColor: "rgb(245,118,10) ",
                            boxShadow: BoxShadow,
                            "&:focus, &:hover, &.Mui-active": {
                              boxShadow:
                                "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
                              // Reset on touch devices, it doesn't add specificity
                              "@media (hover: none)": {
                                boxShadow: BoxShadow,
                              },
                            },
                          },

                          "& .MuiSlider-track": {
                            border: "none",
                            height: 8,
                          },
                          "& .MuiSlider-rail": {
                            opacity: 0.5,
                            height: 8,
                            backgroundColor: "white",
                            border: "1px solid #C7CCD1",
                            borderRadius: 8,
                          },
                          "& .MuiSlider-markLabel": {
                            marginTop: 7,
                            "&.MuiSlider-markLabelActive": {
                              opacity: 1,
                              color: "rgba(245,118,10,0.9)",
                            },
                          },
                          "& .MuiSlider-mark": {
                            backgroundColor: "#bfbfbf",
                            height: 8,
                            width: 2,
                            marginTop: 12,
                            "&.MuiSlider-markActive": {
                              opacity: 1,
                              backgroundColor: "rgb(245,118,10)",
                            },
                          },
                        }}
                      />
                    </div>
                    <div
                      className="display_flex flex_center bg_neutral_0 text_primary_300 br_xxs  cursor_pointer dim_25_px"
                      onClick={handleButtonSum}
                      style={{
                        WebkitUserSelect: "none",
                        MozUserSelect: "none",
                        msUserSelect: "none",
                        userSelect: "none",
                      }}
                    >
                      +
                    </div>
                  </div>
                </div>

                <Typography scale="small" weight="600" className="m_y_xs">
                  Visualiza tus descuento{" "}
                  <span className="text_primary_300">en tiempo real</span>
                </Typography>
                {selectPlanArr?.map((item, idx) => (
                  <PlanSelectionBox
                    title={item.title}
                    key={`${item.weeks}-${idx}-normal`}
                    weeks={item.weeks}
                    weeklyAmount={item.weeklyAmount}
                    handlePlanClick={handlePlanClick}
                    index={idx}
                    selectedPlanIndex={selectedPlanIndex}
                    noDiscountAmount={item.noDiscountAmount}
                  />
                ))}
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: !matchesXS ? "" : "flex-end",
                    }}
                  >
                    <Button
                      disableElevation
                      className="m_y_md"
                      variant="contained"
                      endIcon={<ChevronRight />}
                      style={{
                        backgroundColor: "#FE8A02",
                        color: "white",
                        fontSize: "14px",
                      }}
                      onClick={openFinancialForm}
                    >
                      Seleccionar y continuar
                    </Button>
                  </div>
                </div>
              </Modal>
            </div>
          </Grid>
        </Grid>
        <Grid
          sx={{
            flexGrow: 1,
            padding: !matchesXS ? "0 10px" : "3vh 5vw",
          }}
          container
          justifyContent="start"
          alignItems="start"
          columnSpacing={0}
          rowSpacing={0}
        >
          <Grid item xs={12} sm={12} className="m_t_lg p_x_md">
            <img
              height="48"
              src={LogoMini}
              alt="mini"
              className="display_none_mobile"
            />
            <br />
            <Typography scale="large" weight="600" className="p_b_lg ">
              Conoce todo sobre esta
              <span className="text_primary_300">
                {" "}
                {vehicle?.brand.name} {vehicle?.model.name}{" "}
                {vehicle?.cylindersCapacity}
                {vehicle?.suffix ? vehicle.suffix : ""}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className="p_x_md">
            <div style={{ width: "100%" }}>
              <DescriptionRow label="Marca" description={vehicle?.brand.name} />
              <DescriptionRow
                label="Modelo"
                description={vehicle?.model.name}
              />
              <DescriptionRow label="Año" description={vehicle?.year.toString()} />
              <DescriptionRow
                label="Cilindraje"
                description={vehicle?.cylindersCapacity.toString()}
              />
              <DescriptionRow
                label="Color Principal"
                description={getColorLabelEs(vehicle?.color || "")}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className="p_x_md">
            <div style={{ width: "100%", paddingTop: 0, marginBottom: "5vh" }}>
              <DescriptionRow
                label="Color Secundario"
                description={getColorLabelEs(vehicle?.secondaryColor || "")}
              />
              <DescriptionRow
                label="Tipo de frenos"
                description={vehicle?.brakeType}
              />
              <DescriptionRow
                label="Num. de motor"
                description={vehicle?.engineSN}
              />
            </div>
          </Grid>
        </Grid>

        <Grid
          sx={{
            flexGrow: 1,
            padding: !matchesXS ? "0 10px" : "3vh 5vw",
            marginBottom: "5vh",
          }}
          container
          justifyContent="center"
          columnSpacing={0}
          rowSpacing={2}
        >
          <Grid item xs={12} sm={12} md={12} className="p_md">
            <Typography scale="small" weight="600" className="">
              Detalles
            </Typography>
            <div
              style={{
                borderBottom: "1px solid #C9C9C9",
                padding: 6,
                marginRight: "10px",
                height: "auto",
              }}
            />
            <TextContainer
              text={vehicle?.description || "Sin Información"}
              maxLength={350}
            />
          </Grid>
        </Grid>
      </Container>
      {/* <RecommendedDetails /> */}
      {/* <ContactHelper /> */}
    </>
  );
};

export default NewDetailsView;
