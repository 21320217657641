import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { Typography, Button } from "design_system/src";
import { ReactComponent as Right } from "design_system/src//static/icons/Rigth.svg";
import { getQueryParams } from "helpers/queryParams";
import { fetchGetTempToken, fetchValidateOzoner } from "models/auth-client/controllers/auth.controller";
import { FinancialFormStatusEnum } from "models/financialForm/enums/financialForm.enums";
import { useDispatch } from "react-redux";
import { logout } from "store/actions/user";

const steps = [
    {
        title: "Información personal",
        completed: true,
    },
    {
        title: "Ingresos y gastos",
        completed: true,
    },
    {
        title: "Dependientes y Empleo",
        completed: true,
    },
    {
        title: "Estado civil y Activos",
        completed: true,
    },
    {
        title: "Verificación de identidad",
        completed: false,
    },
    {
        title: "Comprobante de domicilio",
        completed: false,
    },
    {
        title: "Certificados de ingresos",
        completed: false,
    },
];

type State = {
    email: string;
    status: FinancialFormStatusEnum.PENDING;
}

export const ContinueView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { email, id } = getQueryParams(window.location.search);
    const [completedSteps] = useState(steps);

    const toContinue = () => {
        if (email) {
            fetchGetTempToken(email).then((data) => {
                if (data.financialFormId) {
                    navigate(`/financia-tu-moto/documentos/${data.financialFormId}`);
                }
            });
        }
    };

    useLayoutEffect(() => {
        if (email) {
            fetchValidateOzoner({ email })
            .then(({exist, financialFormStatus}) => {
                if (exist && (
                    financialFormStatus === FinancialFormStatusEnum.PENDING ||
                    financialFormStatus === FinancialFormStatusEnum.CONTACTED
                )) {
                    dispatch(logout());
                    if (id) {
                        ReactGA.event("CTA_continue_documents", {
                            category: "Emails",
                            label: "click in CTA to continue documents upload journey",
                            userId: id,
                            userEmail: email,
                        });
                    }
                    ReactGA.event("VIEW_Financial_summary", {
                        category: "Emails",
                        label: "page view with summary steps information",
                    });
                } else {
                    navigate("/");
                }
            });
        } else {
            navigate("/");
        }
    }, []);

    return (
        <div className="dso_container" style={{ minHeight: "70vh" }}>
            <div className="m_y_lg_mobile m_y_xxxl p_y_xxl">
                <Typography
                    scale="heading3"
                    scaleMobile="large"
                    weight="600"
                    className="text_center m_b_md"
                >
                    Hola, parece que ya tienes una solicitud en proceso
                </Typography>
                <Typography
                    scale="large"
                    weight="400"
                    className="text_center m_b_xxl display_none_mobile"
                >
                    Termina de llenar tus datos y prepárate para
                    <Typography scale="large" weight="600" component="span">
                    {" "}
                    obtener tu moto ideal
                    </Typography>
                </Typography>
                <div className="p_lg bg_neutral_200 br_sm display_flex flex_gap_md pos_relative m_b_lg flex_col_mobile">
                    <div className="continue_bar bg_neutral_400 display_none_mobile" />
                    <div className="continue_bar_mobile bg_neutral_400 display_none_desktop" />
                    {completedSteps.map((step, i) => (
                        <div
                            className="flex_center flex_gap_xs flex_gap_lg_mobile flex_col_desktop flex_justify_start_mobile p_x_lg_mobile z_index_1"
                            key={step.title}
                        >
                            <div
                                className={`br_circle dim_xl flex_center text_neutral_0 ${
                                    step.completed
                                    ? "bg_primary_300"
                                    : "bg_neutral_600_nocontrast"
                                }`}
                            >
                                <Typography
                                    scale="medium"
                                    scaleMobile="xsmall"
                                    weight="600"
                                    className=""
                                >
                                    {i + 1}
                                </Typography>
                            </div>
                            <Typography
                                scale="medium"
                                weight="600"
                                className=""
                                textColor={step.completed ? "primary_300" : "neutral_600"}
                            >
                                {step.title}
                            </Typography>
                        </div>
                    ))}
                </div>

                <div className="flex_center flex_col m_b_xxxl">
                    <Typography
                        scale="medium"
                        weight="600"
                        className="m_b_md text_center"
                        >
                        Te tomará solo 5 minutos termínarla, ¿Qué esperas?
                    </Typography>
                    <div className="flex_center flex_col_mobile flex_gap_md">
                        <Button
                            icon={<Right />}
                            orientation="right"
                            onClick={toContinue}
                            className="display_none_mobile"
                            >
                            Continuar
                        </Button>
                        <Button
                            scale="small"
                            icon={<Right />}
                            orientation="right"
                            onClick={toContinue}
                            className="display_none_desktop"
                            >
                            Continuar
                        </Button>
                        <Button
                            icon={<Right />}
                            orientation="right"
                            onClick={() => navigate("/mi-cuenta")}
                            className="display_none_mobile"
                            variant="outline"
                            >
                            Gestionarla desde Mi Cuenta
                        </Button>
                        <Button
                            scale="small"
                            icon={<Right />}
                            orientation="right"
                            onClick={() => navigate("/mi-cuenta")}
                            className="display_none_desktop"
                            variant="outline"
                            >
                            Gestionarla desde Mi Cuenta
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
