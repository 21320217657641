/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  clearApplicationLoanTime,
  clearApplicationVehicle,
} from "store/actions/application";

import backgroundDesktopImage from "static/images/banner/banner_desktop.jpg";
import backgroundImage from "static/images/banner/banner_mobile.jpg";

export const OneThousandSection = () => {
  const dispatch = useDispatch();

  const clickToFinancialForm = (alliedCompany: string  | null = null) => {
    dispatch(clearApplicationLoanTime());
    dispatch(clearApplicationVehicle());
  };

  return (
    <Link to="/financia-tu-moto" onClick={() => clickToFinancialForm()}>
      <section className="bannerSection">
        <div className="p_x_none pos_relative">
          <img
            src={backgroundImage}
            alt="Banner landing page"
            className="display_none_desktop w_100_per_mobile"
          />
          <img
            src={backgroundDesktopImage}
            alt="Banner landing page"
            className="display_none_mobile w_100_per_desktop"
          />
        </div>
      </section>
    </Link>
  );
};
