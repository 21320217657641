import { useState, useEffect } from "react";
import { RootState, store } from "store";
import { useNavigate } from "react-router-dom";
import { logout } from "store/actions/user";
import { useSelector } from "react-redux";
import { useFinancialForm } from "hooks/useFinancialForm";

function useAuth() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const { loading: loadingFinancialForm, financialForm } = useFinancialForm();
    const { jwt, refreshToken, user, tempToken } = useSelector((state: RootState) => state.userReducer);
    const navigate = useNavigate();

    useEffect(() => {
        if (tempToken || (!loadingFinancialForm && financialForm == null)) {
            store.dispatch(logout());
            navigate("/ingresar");
        }

        if (!loadingFinancialForm) {
            const fetchAuthStatus = async () => {
                setIsAuthenticated(jwt !== "" && refreshToken && refreshToken !== "" && user.account?.activationDate && financialForm !== null);
                setLoading(false);
            };

            fetchAuthStatus();
        }
    }, [loadingFinancialForm]);

    return { isAuthenticated, loading };
}

export default useAuth;
