/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Countdown from "react-countdown";

import { store } from "store";
import { useFinancialForm } from "hooks/useFinancialForm";
import { logout } from "store/actions/user";

import { useDispatch } from "react-redux";
import { fetchFinancialForm, removeFinancialForm } from "store/actions/financialForm";

import { AuthCard } from "views/auth/AuthCard";
import { Typography } from "design_system/src";
import { OzonFlyForm } from "./ozonFlyForm/OzonFlyForm";
import { OzonFlyFormCompleted } from "./ozonFlyForm/OzonFlyFormCompleted";

type param = {
    formId: string;
};

type jsonProps = {
    exp?: number;
}

type countDown = {
    minutes: number;
    seconds: number;
    completed: boolean;
}

export const OzonFlyScreen: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { formId } = useParams();
    const { tempToken } = store.getState().userReducer;
    const { financialForm, loading: loadingFinancialForm } = useFinancialForm();

    const [loading, setLoading] = useState(true);
    const [targetDate, setTargetDate] = useState(Date.now());
    const [workDigitalPlatforms, setWorkDigitalPlatforms] = useState<boolean>(false);
    const [completed, setCompleted] = useState(false);
    const [tokenExpired, setTokenExpired] = useState(false);

    const renderer = (
        { minutes, seconds, completed: countdownCompleted }: countDown
    ) => {
        if (countdownCompleted) {
            setTokenExpired(true);
            dispatch(logout());
            return <div/>;
        }

        return <div className="bg_primary_25 p_x_md p_y_sm br_xs">
            <Typography className="m_none" weight="400" scale="medium" scaleMobile="medium" component="p">
                <strong>Tiempo restante:</strong> {minutes} minutos con {seconds} segundos
            </Typography>
            <Typography className="m_none" weight="400" scale="medium" scaleMobile="medium" component="p">
                Puedes ingresar en cualquier momento dando <Link className="text_primary_300" to="/ingresar">click aquí.</Link>
            </Typography>
        </div>;
    };

    useEffect(() => {
        if (tempToken !== "" && formId) {
            const base64Url = tempToken.split(".")[1];
            const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            const jsonPayload = decodeURIComponent(atob(base64).split("").map((c) => {
                return `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`;
            }).join(""));
            const json = JSON.parse(jsonPayload);
            const tokenTime = json.exp ? (json.exp! * 1000) - new Date().getTime() : 0;
            setTargetDate(Date.now() + tokenTime);
            dispatch(fetchFinancialForm(formId));
        } else {
            setLoading(false);
        }

        return () => {
            store.dispatch(logout());
        };
    }, []);

    useEffect(() => {
        if (financialForm && !loadingFinancialForm) {
            setWorkDigitalPlatforms(financialForm.financialInformation.workInDigitalPlatforms);
            if (financialForm?._id !== formId) setTargetDate(Date.now());
            setLoading(false);
        }
    }, [financialForm, loadingFinancialForm]);

    return !loading ? (
        <div className="dso_container m_y_xxl" style={{ minHeight: "75vh", maxWidth: 1440 }}>
            {!tokenExpired && <div>
                <Countdown
                    date={targetDate}
                    renderer={renderer}
                />
                {!completed ? (
                    <OzonFlyForm workDigitalPlatforms={workDigitalPlatforms} complete={() => setCompleted(true)} />
                ) : (
                    <OzonFlyFormCompleted />
                )}
            </div>}
            {tokenExpired && <div className="m_y_lg_mobile m_y_xxxl_desktop m_x_auto w_600_px_desktop">
                <AuthCard
                    title="El token ha expirado"
                    subtitle={<p>Te invitamos a seguir con el proceso ingresando a Mi Cuenta</p>}
                    buttonTitle="Ingresar a Mi Cuenta"
                    buttonAction={() => navigate("/ingresar")}
                />
            </div>}
        </div>
    ) : (
        <div className="dso_container m_y_xxl" style={{ minHeight: "75vh", maxWidth: 1440 }}>
            Cargando...
        </div>
    );
};
