import Swal from "sweetalert2";
import {
  ValidateOzonerResponse,
  ValidateOzonerRequest,
  ValidateOzonerPhoneResponse,
} from "../interfaces/auth-client.interface";
import { PandaboardApiInstance } from "../../../helpers/api";

export const fetchRefreshToken = (refreshToken: string) =>
  PandaboardApiInstance.post(
    "/auth-client/refresh",
    { refresh_token: refreshToken },
    {
      headers: { refreshRequest: true },
    }
  );

export const fetchValidateOzoner = async (data: ValidateOzonerRequest) =>
  PandaboardApiInstance.post<ValidateOzonerResponse>("auth-client/validate-ozoner", data)
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchValidateCurp = async (curp: string) =>
  fetchValidateOzoner({ curp })
    .then(({ exist, email, financialFormStatus }) => {
      if (exist && financialFormStatus) {
        Swal.fire({
          text: `Este CURP ya se encuentra vinculado a una solicitud con el correo ${email}, ingresa un CURP valido.`,
          title: "¡TU CURP YA ESTÁ REGISTRADO!",
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "bg_primary_300 w_90_per",
            title: "text_neutral_800",
            container: "text_neutral_700",
          },
        });
      }
      return !(exist && financialFormStatus);
    })
    .catch((err: any) => {
      if (err.response && err.response.status === 400) {
        Swal.fire({
          text: "¡CURP invalido!",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
      throw err.response.data;
    });

export const fetchValidatePhone = async (phone: string) =>
  PandaboardApiInstance.post<ValidateOzonerPhoneResponse>("auth-client/validate-phone", { phone })
    .then((res) => {
      const { exists } = res.data;
      if (exists) {
        Swal.fire({
          text: "El numero que escribiste ya esta en otra solicitud. Usa otro o dirígete a la sección Mi Cuenta.",
          title: "¡Ups! Número ya registrado",
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "bg_primary_300 w_90_per",
            title: "text_neutral_800",
            container: "text_neutral_700",
          },
        });
      }
      return exists;
    })
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchLogin = async (curp: string, password: string) =>
  PandaboardApiInstance
    .post("auth-client/login", { curp, password })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchLogout = async () =>
  PandaboardApiInstance
    .get("auth-client/logout", { requireAuth: true })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchOtpPassword = async (curp: string) =>
  PandaboardApiInstance
    .post(`auth-client/otp/${curp}`)
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchOtpValidation = async (curp: string, otp: string) =>
  PandaboardApiInstance
    .post("auth-client/otp/validate", { curp, otp })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchChangePassword = async (password: string, confirmPassword: string) =>
  PandaboardApiInstance
    .post("auth-client/reset-password", { password, confirmPassword}, { requireAuth: true })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchGetTempToken = async (email: string) =>
  PandaboardApiInstance
    .post("auth-client/temp-token", { email }, { requireAuth: true })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });