/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from "react";
import useVehicles from "hooks/useVehicles";
import { OrderType } from "store/reducers/vehiclesReducer";
import { Input, Typography } from "design_system/src";
import { useMediaQuery } from "@mui/material";
import "./SortVehicles.scss";
import { VehicleSortTypeEnum } from "models/inventory/enums/vehicle.enums";

interface Props {
  updateCatalogFilters: any;
}

export const SortVehicles: React.FC<Props> = ({
  updateCatalogFilters
}) => {
  const { selectedFilters: { sort } = {} } = useVehicles();
  const matchesXS = useMediaQuery("(min-width:600px)");

  interface IOrderoptions {
    value: OrderType | string;
    label: string;
  }

  const orderOptions: IOrderoptions[] = [
    { value: VehicleSortTypeEnum.SALES_RANKING, label: "Más Populares" },
    { value: VehicleSortTypeEnum.PROMOTION, label: "En promoción" },
    { value: VehicleSortTypeEnum.HIGHER_PRICE, label: "Precio más alto" },
    { value: VehicleSortTypeEnum.LOWER_PRICE, label: "Precio más bajo" },
    { value: VehicleSortTypeEnum.HIGHER_CC, label: "Mayor CC" },
    { value: VehicleSortTypeEnum.LOWER_CC, label: "Menor CC" },
    { value: VehicleSortTypeEnum.HIGHER_KM, label: "Más KM" },
    { value: VehicleSortTypeEnum.LOWER_KM, label: "Menos KM" },
  ];

  const [selectedOption, setSelectedOption] = useState<any>( sort ?? VehicleSortTypeEnum.SALES_RANKING);

  const handleSelectChange = (e: any) => {
    const value = e.target.value;
    setSelectedOption(value);
    updateCatalogFilters({"sort": value !== "" ? value : undefined});
  };

  useEffect(() => {
    if (selectedOption === "" && sort) {
      if (!Object.values(VehicleSortTypeEnum).includes(sort as any)) {
        setSelectedOption("");
        updateCatalogFilters({"sort": undefined});
      } else {
        setSelectedOption(sort);
      }
    }
  }, [sort]);

  return (
    <div className={`display_flex ${!matchesXS ? " flex_col" : " flex_align_center"}`}>
      <Typography
        scale="small"
        weight="400"
        className="text_neutral_600 m_r_sm"
        style={{ marginBottom: matchesXS ? 0 : "-1rem" }}
      >
        Ordenar por:
      </Typography>
      <Input
        title=""
        placeholder=""
        name="sortVehicles"
        type="select"
        options={orderOptions}
        className="inputSelect"
        value={selectedOption}
        onChange={handleSelectChange}
      />
    </div>
  );
};
