/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import _ from "lodash";
import { useFormikContext } from "formik";
import Grid from "@mui/material/Grid";
import {
  Input,
  OzonSelect,
  Typography,
} from "design_system/src";

import { useSelector } from "react-redux";
import { RootState } from "store";


import { ReactComponent as Moto } from "design_system/src//static/icons/motorcycle.svg";
import { ReactComponent as Home } from "design_system/src//static/icons/home.svg";
import { ReactComponent as Boy } from "design_system/src//static/icons/boy.svg";
import { ReactComponent as Invoice } from "design_system/src//static/icons/invoice.svg";
import { ReactComponent as InformationTriangle } from "design_system/src//static/icons/information-triangle.svg";
import { ReactComponent as StarsProfile } from "design_system/src//static/icons/stars-profile.svg";

import { ReactComponent as UserCard2 } from "design_system/src/static/icons/user-card-2.svg";
import { FormControl } from "@mui/material";
import { ValidationIdentityFormEnum } from "enums/validationIdentityForm.enum";

import { formatPhone, unformatPhone } from "helpers/phone";
import UploadInput from "../uploadInput/UploadInput";
import ineF from "../../../static/images/validateIdentity/ineF.png";
import ineB from "../../../static/images/validateIdentity/ineB.png";
import selfie from "../../../static/images/validateIdentity/selfie.png";
import { useUploadFiles } from "../hooks/useUploadFiles";

/* eslint-disable react/no-unused-prop-types */
type StepProps = {
  workDigitalPlatforms?: boolean;
  location?: string;
};

const tooltipData = [
  {
    title: "¿Cómo tomar una foto a tu INE?",
    step1: "De frente sin Flash",
    step2: "No deberá tener reflejos o sombras que impidan su legibilidad.",
    image: ineF,
  },
  {
    title: "¿Cómo tomar una foto a tu INE?",
    step1: "De atras sin Flash",
    step2: "No deberá tener reflejos o sombras que impidan su legibilidad.",
    image: ineB,
  },
  {
    title: "¿Como tomarte Selfie?",
    step1: "Rostro Centrado",
    step2: "De frente mirando a la camara.",
    image: selfie,
  },
];

/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum relationOptionsValues {
  OTHER = "Otro"
}

const relationOptions = [
  { label: "Familiar", value: "Familiar" },
  { label: "Pareja", value: "Pareja" },
  { label: "Amigo/a", value: "Amigo/a" },
  { label: "Compañero de trabajo", value: "Compañero de trabajo" },
  { label: "Otro", value: relationOptionsValues.OTHER },
];
const documentTooltip = (id: number) => (
  <div className="display_flex flex_gap_xxs">
    <div className="p_y_md p_l_lg">
      <Typography weight="600" scale="medium" className=" text_center m_b_md">
        {tooltipData[id].title}
      </Typography>
      <Typography
        weight="600"
        scale="xxsmall"
        className="m_b_sm"
        textColor="neutral_900"
      >
        <Typography
          weight="600"
          scale="xxsmall"
          textColor="primary_300"
          component="span"
        >
          {"1. "}
        </Typography>
        {tooltipData[id].step1}
      </Typography>
      <Typography
        weight="600"
        scale="xxsmall"
        className=""
        textColor="neutral_900"
      >
        <Typography
          weight="600"
          scale="xxsmall"
          textColor="primary_300"
          component="span"
        >
          {"2. "}
        </Typography>
        {tooltipData[id].step2}
      </Typography>
    </div>
    <img src={tooltipData[id].image} className="h_100_per" alt="" />
  </div>
);

export const StepOneA: React.FC<StepProps> = ({ location = "OzonFly" }) => {
  const useChangeFile = useUploadFiles();
  const { values, errors } = useFormikContext<any>();

  useEffect(() => {
    ReactGA.event(`VIEW_${location}_ine`, {
      category: `VIEW_${location}_ine`,
      label: "page view to the ine certificate view",
    });
  }, []);

  return (
    <div className="animate__animated animate__fadeIn p_x_lg p_y_xl">
      <Grid container>
        <Grid
          item
          md={6}
          xs={12}
          className="p_x_xxxl_desktop p_x_md_mobile p_y_md_mobile"
        >
          <UploadInput
            onChange={(props) => useChangeFile({...props, location})}
            value={_.get(values, ValidationIdentityFormEnum.INE_FRONT)}
            name={ValidationIdentityFormEnum.INE_FRONT}
            tooltip={documentTooltip(0)}
            error={(_.get(errors, ValidationIdentityFormEnum.INE_FRONT) as string) ?? undefined}
            title="Sube la foto de tu INE por la "
            titleFocus="parte frontal"
            placeholderIcon={<UserCard2 className="dim_xl" />}
            accept="image/jpeg,image/png"
          />
        </Grid>
        <Grid item md={6} xs={12} className="p_x_xxxl_desktop p_x_md_mobile ">
          <UploadInput
            onChange={(props) => useChangeFile({...props, location})}
            value={_.get(values, ValidationIdentityFormEnum.INE_BACK)}
            name={ValidationIdentityFormEnum.INE_BACK}
            tooltip={documentTooltip(1)}
            error={(_.get(errors, ValidationIdentityFormEnum.INE_BACK) as string) ?? undefined}
            title="Sube la foto de tu INE por la "
            titleFocus="parte posterior"
            placeholderIcon={<UserCard2 className="dim_xl" />}
            accept="image/jpeg,image/png"
          />
        </Grid>
      </Grid>
    </div>
  );
};
export const StepOneB: React.FC<StepProps> = ({ location = "OzonFly" }) => {
  const useChangeFile = useUploadFiles();
  const { values, errors } = useFormikContext<any>();

  useEffect(() => {
    ReactGA.event(`VIEW_${location}_selfie`, {
      category: `VIEW_${location}_selfie`,
      label: "page view to the selfie photo view",
    });
  }, []);

  return (
    <div className="animate__animated animate__fadeIn p_x_lg p_y_xl">
      <Grid container justifyContent="center">
        <Grid item md={6} xs={12} className="p_x_xxxl_desktop p_x_md_mobile">
          <UploadInput
            onChange={(props) => useChangeFile({...props, location})}
            value={_.get(values, ValidationIdentityFormEnum.SELFIE)}
            name={ValidationIdentityFormEnum.SELFIE}
            tooltip={documentTooltip(2)}
            error={(_.get(errors, ValidationIdentityFormEnum.SELFIE) as string) ?? undefined}
            title="¡Genial! ahora "
            titleFocus="tómate una selfie"
            placeholderIcon={<Boy className="dim_xl" />}
            accept="image/jpeg,image/png"
          />
        </Grid>
      </Grid>
    </div>
  );
};
export const StepTwoA: React.FC<StepProps> = ({ location = "OzonFly" }) => {
  const useChangeFile = useUploadFiles();
  const { values, errors, getFieldMeta } = useFormikContext<any>();

  useEffect(() => {
    ReactGA.event(`VIEW_${location}_address`, {
      category: `VIEW_${location}_address`,
      label: "page view to the address certificate view",
    });
  }, []);

  return (
    <div className="animate__animated animate__fadeIn p_x_lg p_y_xl">
      <Grid container justifyContent="center">
        <Grid item md={6} xs={12} className="p_x_xxxl_desktop p_x_md_mobile">
          <UploadInput
            onChange={(props) => useChangeFile({...props, location})}
            value={_.get(values, ValidationIdentityFormEnum.PROOF_OF_ADDRESS)}
            name={ValidationIdentityFormEnum.PROOF_OF_ADDRESS}
            title="Comprobante de domicilio"
            titleFocus={location === "OzonFly" ? " (opcional)" : ""}
            subTitle="Sube un recibo de agua, luz o gas de menos de 3 meses"
            placeholderIcon={<Home className="dim_xl" />}
            error={
              _.get(errors, ValidationIdentityFormEnum.PROOF_OF_ADDRESS) &&
              getFieldMeta(ValidationIdentityFormEnum.PROOF_OF_ADDRESS).touched
                ? (_.get(errors, ValidationIdentityFormEnum.PROOF_OF_ADDRESS) as string)
                : ""
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};
export const StepTwoB: React.FC<StepProps> = ({ workDigitalPlatforms, location = "OzonFly" }) => {
  const useChangeFile = useUploadFiles();
  const { user } = useSelector((state: RootState) => state.userReducer);
  const { values, errors, getFieldMeta } = useFormikContext<any>();

  useEffect(() => {
    ReactGA.event(`VIEW_${location}_bank_account`, {
      category: `VIEW_${location}_bank_account`,
      label: "page view to the bank account certificates view",
    });
  }, []);

  return (
    <div className="animate__animated animate__fadeIn p_y_xl">
      <div className="display_flex flex_align_center flex_justify_start m_b_lg">
        <div className="display_flex flex_center dim_xl">
          <div className="bg_primary_300 br_circle dim_10_px" />
        </div>
        <Typography
          weight="400"
          scale="small"
          className="w_100_per center_x"
        >
          {"Sube los Estados de cuenta "}
          <Typography weight="600" scale="small" className="" component="span">
            {"de los últimos 3 meses "}
          </Typography>
          (En formato PDF)
        </Typography>
      </div>
      {!_.get(values, ValidationIdentityFormEnum.PROOF_OF_ADDRESS) && workDigitalPlatforms && <div className="display_flex flex_align_center flex_justify_start m_b_lg">
        <InformationTriangle className="dim_xl text_primary_300"/>
        <Typography
          weight="400"
          scale="small"
          className="w_100_per center_x"
        >
          <Typography weight="600" scale="small" className="" component="span">
            {"¿Eres repartidor y no puedes demostrar tus ganancias? "}
          </Typography>
          No te preocupes, puedes terminar y te contactaremos por correo electrónico.
        </Typography>
      </div>}
      {_.get(values, ValidationIdentityFormEnum.PROOF_OF_ADDRESS) && workDigitalPlatforms && <div className="display_flex flex_align_center flex_justify_start m_b_lg">
        <InformationTriangle className="dim_xl text_primary_300"/>
        <Typography
          weight="400"
          scale="small"
          className="w_100_per center_x"
        >
          <Typography weight="600" scale="small" className="" component="span">
          ¿Eres repartidor de Rappi, Uber, Didi o similares?
          </Typography>
          <br/>
          {"Comprueba tus ingresos "}
          <a
            className="text_primary_300"
            style={{ fontWeight: 600 }}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://connect.palenca.com/?widget_id=7c3011ec-7872-4e8a-bdf2-8c0f93e266d7&external_id=${user._id}`}
          >aquí</a>
        </Typography>
      </div>}
      <Grid container>
        <Grid item md={4} xs={12} className="p_x_lg">
          <UploadInput
            onChange={(props) => useChangeFile({...props, location})}
            accept="application/pdf"
            title="1er Comprobante"
            titleFocus={location === "OzonFly" ? " (opcional)" : ""}
            value={_.get(values, ValidationIdentityFormEnum.BANK_CERTIFICATE_1)}
            name={ValidationIdentityFormEnum.BANK_CERTIFICATE_1}
            placeholderIcon={<Invoice className="dim_xl" />}
            error={
              _.get(errors, ValidationIdentityFormEnum.BANK_CERTIFICATE_1) &&
              getFieldMeta(ValidationIdentityFormEnum.BANK_CERTIFICATE_1).touched
                ? (_.get(errors, ValidationIdentityFormEnum.BANK_CERTIFICATE_1) as string)
                : ""
            }
          />
        </Grid>
        <Grid item md={4} xs={12} className="p_x_lg m_y_md_mobile">
          <UploadInput
            onChange={(props) => useChangeFile({...props, location})}
            accept="application/pdf"
            title="2do Comprobante"
            titleFocus={location === "OzonFly" ? " (opcional)" : ""}
            value={_.get(values, ValidationIdentityFormEnum.BANK_CERTIFICATE_2)}
            name={ValidationIdentityFormEnum.BANK_CERTIFICATE_2}
            placeholderIcon={<Invoice className="dim_xl" />}
            error={
              _.get(errors, ValidationIdentityFormEnum.BANK_CERTIFICATE_2) &&
              getFieldMeta(ValidationIdentityFormEnum.BANK_CERTIFICATE_2).touched
                ? (_.get(errors, ValidationIdentityFormEnum.BANK_CERTIFICATE_2) as string)
                : ""
            }
          />
        </Grid>
        <Grid item md={4} xs={12} className="p_x_lg">
          <UploadInput
            onChange={(props) => useChangeFile({...props, location})}
            accept="application/pdf"
            title="3er Comprobante"
            titleFocus={location === "OzonFly" ? " (opcional)" : ""}
            value={_.get(values, ValidationIdentityFormEnum.BANK_CERTIFICATE_3)}
            name={ValidationIdentityFormEnum.BANK_CERTIFICATE_3}
            placeholderIcon={<Invoice className="dim_xl" />}
            error={
              _.get(errors, ValidationIdentityFormEnum.BANK_CERTIFICATE_3) &&
              getFieldMeta(ValidationIdentityFormEnum.BANK_CERTIFICATE_3).touched
                ? (_.get(errors, ValidationIdentityFormEnum.BANK_CERTIFICATE_3) as string)
                : ""
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};
export const StepTwoC: React.FC<StepProps> = () => {
  const useChangeFile = useUploadFiles();
  const { values } = useFormikContext<any>();

  useEffect(() => {
    ReactGA.event("VIEW_OzonFly_driver_license", {
      category: "VIEW_OzonFly_driver_license",
      label: "page view to the driver license view",
    });
  }, []);

  return (
    <div className="animate__animated animate__fadeIn p_x_lg p_y_xl">
      <Grid container>
        <Grid
          item
          md={6}
          xs={12}
          className="p_x_xxxl_desktop p_x_md_mobile p_y_md_mobile"
        >
          <UploadInput
            onChange={useChangeFile}
            value={values.step2C.license_front}
            name="step2C.license_front"
            title="Sube la foto de tu licencia de conducir por la "
            titleFocus="parte frontal (opcional)"
            placeholderIcon={<Moto className="dim_xl" />}
            accept="image/jpeg,image/png"
          />
        </Grid>
        <Grid item md={6} xs={12} className="p_x_xxxl_desktop p_x_md_mobile">
          <UploadInput
            onChange={useChangeFile}
            value={values.step2C.license_back}
            name="step2C.license_back"
            title="Sube la foto de tu licencia de conducir por la "
            titleFocus="parte posterior (opcional)"
            placeholderIcon={<Moto className="dim_xl" />}
            accept="image/jpeg,image/png"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export const StepTwoD: React.FC<StepProps> = () => {
  const useChangeFile = useUploadFiles();
  const { values } = useFormikContext<any>();

  useEffect(() => {
    ReactGA.event("VIEW_OzonFly_fiscal", {
      category: "VIEW_OzonFly_fiscal",
      label: "page view to the fiscal certificate view",
    });
  }, []);

  return (
    <div className="animate__animated animate__fadeIn p_x_lg p_y_xl">
      <Grid container alignItems="center" justifyContent="center">
        <Grid item md={6} xs={12} className="p_x_xxxl_desktop p_x_md_mobile ">
          <UploadInput
            onChange={useChangeFile}
            value={values.step2D.tax_situation}
            name="step2D.tax_situation"
            title="Sube constancia de situación fiscal"
            titleFocus=" formato PDF (opcional)"
            placeholderIcon={<Boy className="dim_xl" />}
            accept="application/pdf"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export const StepThree: React.FC<StepProps> = ({ location = "OzonFly" }) => {
  const {
    values,
    handleChange,
    errors,
    handleBlur,
    getFieldMeta,
    setFieldValue,
  } = useFormikContext<any>();

  const [showRef1OtherField, setShowRef1OtherField] = useState<boolean>(false);
  const [showRef2OtherField, setShowRef2OtherField] = useState<boolean>(false);
  const [relation1Field, setRelation1Field] = useState<string>();
  const [relation2Field, setRelation2Field] = useState<string>();

  const updatePhone = (value: string, field: string) => {
    const phoneValue = formatPhone(value);
    setFieldValue(field, phoneValue);
  };

  useEffect(() => {
    const relation1 = _.get(values, ValidationIdentityFormEnum.REFERENCE_1_RELATION);
    const relation2 = _.get(values, ValidationIdentityFormEnum.REFERENCE_2_RELATION);

    if (relation1) {
      const showOtherField = !relationOptions
        .filter(({ value }) => value !== relationOptionsValues.OTHER)
        .map(({ value }) => value).includes(relation1);

      setShowRef1OtherField(showOtherField);
      setRelation1Field(showOtherField ? relationOptionsValues.OTHER : relation1);
    }

    if (relation2) {
      const showOtherField = !relationOptions
        .filter(({ value }) => value !== relationOptionsValues.OTHER)
        .map(({ value }) => value).includes(relation2);

      setShowRef2OtherField(showOtherField);
      setRelation2Field(showOtherField ? relationOptionsValues.OTHER : relation2);
    }
  }, []);

  useEffect(() => {
    ReactGA.event(`VIEW_${location}_personal_references`, {
      category: `VIEW_${location}_personal_references`,
      label: "page view to the personal references view",
    });

    updatePhone(
      unformatPhone(_.get(values, ValidationIdentityFormEnum.REFERENCE_1_PHONE)!),
      ValidationIdentityFormEnum.REFERENCE_1_PHONE
    );

    updatePhone(
      unformatPhone(_.get(values, ValidationIdentityFormEnum.REFERENCE_2_PHONE)!),
      ValidationIdentityFormEnum.REFERENCE_2_PHONE
    );
  }, []);

  const handleRelation1Field = (e: any) => {
    const showOtherField = e.target.value === relationOptionsValues.OTHER;
    setShowRef1OtherField(showOtherField);
    setRelation1Field(e.target.value);
    setFieldValue(ValidationIdentityFormEnum.REFERENCE_1_RELATION, showOtherField ? "" : e.target.value);
  };

  const handleRelation2Field = (e: any) => {
    const showOtherField = e.target.value === relationOptionsValues.OTHER;
    setShowRef2OtherField(showOtherField);
    setRelation2Field(e.target.value);
    setFieldValue(ValidationIdentityFormEnum.REFERENCE_2_RELATION, showOtherField ? "" : e.target.value);

  };

  const handlePhoneChange1 = (e: any) => {
    handleChange(e);
    updatePhone(e.target.value, ValidationIdentityFormEnum.REFERENCE_1_PHONE);
  };

  const handlePhoneChange2 = (e: any) => {
    handleChange(e);
    updatePhone(e.target.value, ValidationIdentityFormEnum.REFERENCE_2_PHONE);
  };

  return (
    <div className="animate__animated animate__fadeIn">
      <Grid container>
        <Grid item md={6} xs={12} className="p_x_xxl">

          <Input
            type="text"
            title="Referencia 1"
            name={ValidationIdentityFormEnum.REFERENCE_1_NAME}
            icon={<StarsProfile />}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Escribe el nombre completo aquí"
            value={_.get(values, ValidationIdentityFormEnum.REFERENCE_1_NAME)}
            error={
              !!_.get(errors, ValidationIdentityFormEnum.REFERENCE_1_NAME) &&
              getFieldMeta(ValidationIdentityFormEnum.REFERENCE_1_NAME).touched
            }
            subtitle={
              getFieldMeta(ValidationIdentityFormEnum.REFERENCE_1_NAME).touched
                ? (_.get(errors, ValidationIdentityFormEnum.REFERENCE_1_NAME) as string)
                : undefined
            }
          />
          <div className="w_100_per display_flex flex_align_start flex_gap_xs">
            <div className="display_flex flex_center flex_gap_xxs w_30_per bg_neutral_500 m_y_md_mobile m_y_md_desktop p_y_sm_desktop  p_y_xs_mobile br_xxs">
              <img
                src="https://flagcdn.com/w80/mx.png"
                alt="mx"
                height="20"
              />
              <Typography scale="xsmall" weight="600" className="">
                +52
              </Typography>
            </div>
            <div className="w_70_per">
              <Input
                type="text"
                title=""
                name={ValidationIdentityFormEnum.REFERENCE_1_PHONE}
                onChange={handlePhoneChange1}
                onBlur={handleBlur}
                placeholder="Escribe el celular aquí"
                value={_.get(values, ValidationIdentityFormEnum.REFERENCE_1_PHONE)
                  ? unformatPhone(_.get(values, ValidationIdentityFormEnum.REFERENCE_1_PHONE)!)
                  : undefined
                }
                error={
                  !!_.get(errors, ValidationIdentityFormEnum.REFERENCE_1_PHONE) &&
                  getFieldMeta(ValidationIdentityFormEnum.REFERENCE_1_PHONE).touched
                }
                subtitle={
                  getFieldMeta(ValidationIdentityFormEnum.REFERENCE_1_PHONE).touched
                    ? (_.get(errors, ValidationIdentityFormEnum.REFERENCE_1_PHONE) as string)
                    : undefined
                }
              />
            </div>
          </div>
          <div>
            <div style={{ paddingTop: "1rem", marginBottom: "1rem" }}>
              <FormControl sx={{ minWidth: "100%" }} size="small">
                <OzonSelect
                  itemsLabel="Parentesco"
                  fieldName="relation1Field"
                  options={relationOptions}
                  handleChange={handleRelation1Field}
                  values={{relation1Field}}
                  errors={errors}
                />
              </FormControl>
            </div>
          </div>

          {showRef1OtherField && (
            <Input
              error={
                !!_.get(errors, ValidationIdentityFormEnum.REFERENCE_1_RELATION) &&
                getFieldMeta(ValidationIdentityFormEnum.REFERENCE_1_RELATION).touched
              }
              subtitle={
                getFieldMeta(ValidationIdentityFormEnum.REFERENCE_1_RELATION).touched
                  ? (_.get(
                      errors,
                      ValidationIdentityFormEnum.REFERENCE_1_RELATION
                    ) as string)
                  : undefined
              }
              name={ValidationIdentityFormEnum.REFERENCE_1_RELATION}
              placeholder="Escribe el parentesco"
              title=""
              type="text"
              className="m_b_xs w_100_per"
              value={_.get(values, ValidationIdentityFormEnum.REFERENCE_1_RELATION)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          )}
        </Grid>
        <Grid item md={6} xs={12} className="p_x_xxl">
          <Input
            type="text"
            title="Referencia 2"
            name={ValidationIdentityFormEnum.REFERENCE_2_NAME}
            icon={<StarsProfile />}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Escribe el nombre aquí"
            value={_.get(values, ValidationIdentityFormEnum.REFERENCE_2_NAME)}
            error={
              !!_.get(errors, ValidationIdentityFormEnum.REFERENCE_2_NAME) &&
              getFieldMeta(ValidationIdentityFormEnum.REFERENCE_2_NAME).touched
            }
            subtitle={
              getFieldMeta(ValidationIdentityFormEnum.REFERENCE_2_NAME).touched
                ? (_.get(errors, ValidationIdentityFormEnum.REFERENCE_2_NAME) as string)
                : undefined
            }
          />
          <div className="w_100_per display_flex flex_align_start flex_gap_xs">
            <div className="display_flex flex_center flex_gap_xxs w_30_per bg_neutral_500 m_y_md_mobile m_y_md_desktop p_y_sm_desktop  p_y_xs_mobile br_xxs">
              <img
                src="https://flagcdn.com/w80/mx.png"
                alt="mx"
                height="20"
              />
              <Typography scale="xsmall" weight="600" className="">
                +52
              </Typography>
            </div>
            <div className="w_70_per">
              <Input
                type="text"
                title=""
                name={ValidationIdentityFormEnum.REFERENCE_2_PHONE}
                onChange={handlePhoneChange2}
                onBlur={handleBlur}
                placeholder="Escribe el celular aquí"
                value={_.get(values, ValidationIdentityFormEnum.REFERENCE_2_PHONE)
                  ? unformatPhone(_.get(values, ValidationIdentityFormEnum.REFERENCE_2_PHONE)!)
                  : undefined
                }
                error={
                  !!_.get(errors, ValidationIdentityFormEnum.REFERENCE_2_PHONE) &&
                  getFieldMeta(ValidationIdentityFormEnum.REFERENCE_2_PHONE).touched
                }
                subtitle={
                  getFieldMeta(ValidationIdentityFormEnum.REFERENCE_2_PHONE).touched
                    ? (_.get(errors, ValidationIdentityFormEnum.REFERENCE_2_PHONE) as string)
                    : undefined
                }
              />
            </div>
          </div>
          <div>
            <div style={{ paddingTop: "1rem", marginBottom: "1rem" }}>
              <FormControl sx={{ minWidth: "100%" }} size="small">
                <OzonSelect
                  itemsLabel="Parentesco"
                  fieldName="relation2Field"
                  options={relationOptions}
                  handleChange={handleRelation2Field}
                  values={{relation2Field}}
                  errors={errors}
                />
              </FormControl>
            </div>
          </div>

          {showRef2OtherField && (
            <Input
              error={
                !!_.get(errors, ValidationIdentityFormEnum.REFERENCE_2_RELATION) &&
                getFieldMeta(ValidationIdentityFormEnum.REFERENCE_2_RELATION).touched
              }
              subtitle={
                getFieldMeta(ValidationIdentityFormEnum.REFERENCE_2_RELATION).touched
                  ? (_.get(
                      errors,
                      ValidationIdentityFormEnum.REFERENCE_2_RELATION
                    ) as string)
                  : undefined
              }
              name={ValidationIdentityFormEnum.REFERENCE_2_RELATION}
              placeholder="Escribe el parentesco"
              title=""
              type="text"
              className="m_b_xs w_100_per"
              value={_.get(values, ValidationIdentityFormEnum.REFERENCE_2_RELATION)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
