/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum SalePricesWeekEnum {
    WEEKS_1 = 1,
    WEEKS_52 = 52,
    WEEKS_78 = 78,
    WEEKS_104 = 104,
    WEEKS_156 = 156,
}

export enum VehicleSortTypeEnum {
    SALES_RANKING = "salesRanking",
    PROMOTION = "promotion",
    HIGHER_PRICE = "higherPrice",
    LOWER_PRICE = "lowerPrice",
    HIGHER_CC = "higherCC",
    LOWER_CC = "lowerCC",
    HIGHER_KM = "higherKM",
    LOWER_KM = "lowerKM",
}

export enum VehicleStatusEnum {
    AVAILABLE = "available",
    DELIVERED = "delivered",
    ASIDE = "aside",
    LOST = "lost",
    IN_RECOVERY = "inRecovery",
    RECOVERED = "recovered",
    DRAFT = "draft",
}
