import React, {FC, useRef, useState, useEffect} from "react";
import { useFormikContext } from "formik";
import _ from "lodash";

import {
  Typography,
  Input,
  Button,
  OzonSelect,
} from "design_system/src";
import { ReactComponent as Boy } from "design_system/src/static/icons/boy.svg";
import { ReactComponent as Phone } from "design_system/src/static/icons/phone.svg";
import { ReactComponent as Email } from "design_system/src/static/icons/email.svg";
import { ReactComponent as Curp } from "design_system/src/static/icons/user-card-2.svg";
import { ReactComponent as Location } from "design_system/src/static/icons/pin-location.svg";
import { ReactComponent as Chat } from "design_system/src/static/icons/group-chat.svg";
import { ReactComponent as CabinHouse } from "design_system/src/static/icons/cabin-house.svg";
import { ReactComponent as Building } from "design_system/src/static/icons/building.svg";
import { ReactComponent as Groups } from "design_system/src/static/icons/groups.svg";
import { ReactComponent as Mobile } from "design_system/src/static/icons/mobile-2.svg";
import { ReactComponent as Note } from "design_system/src/static/icons/note.svg";
import { ReactComponent as Bill } from "design_system/src/static/icons/bill.svg";

import { FormControl } from "@mui/material";

import NoneIcon from "static/icons/codeBar.png";
import HomeIcon from "static/icons/houseIcon.png";
import CarIcon from "static/icons/carIcon.svg";
import MotoIcon from "static/icons/motoIcon.png";
import CubeIcon from "static/icons/cubeIcon .png";
import { Link } from "react-router-dom";

import AssetCard from "components/assetCard/assetCard";
import FamilyCard from "components/creditCalculationStepper/familyCard/FamilyCard";
import CustomCheckBox from "components/customCheckBox/CustomCheckBox";
import { LivesWithEnum } from "models/shared/enums/personalInformation.enums";
import { AssetsEnum, EconomicActivityEnum } from "models/shared/enums/shared.enums";

import { StepEnum, StepFormValues } from "./constants";
import "./steps.scss";

export const Step1: FC = () => {
  const {
    values,
    handleChange,
    errors,
    handleBlur,
    getFieldMeta,
    setFieldValue,
  } = useFormikContext<StepFormValues>();

  return (
    <>
      <Typography className="m_y_none m_b_md text_center" weight="400" scale="medium" scaleMobile="medium" component="p">
        Llena este formulario para dar el primer paso hacia el financiamiento de tu moto. Tu información estará segura y te contactaremos pronto al finalizar.
      </Typography>

      <Input
        name={StepEnum.name}
        // placeholder="Escribe tu nombre aquí"
        onBlur={handleBlur}
        onChange={handleChange}
        value={_.get(values, StepEnum.name)}
        title="Nombre completo"
        icon={<Boy className="primary_300" />}
        type="text"
        error={
          !!_.get(errors, StepEnum.name) &&
          getFieldMeta(StepEnum.name).touched
        }
        subtitle={
          getFieldMeta(StepEnum.name).touched
            ? (_.get(errors, StepEnum.name))
            : undefined
        }
      />

      <div>
        <div className="dso_input_cont">
          <div className="dso_input_title m_b_none">
            <div className="dso_input_img">
              <Phone />
            </div>
            <div className="dso_input_text">Número de contacto</div>
          </div>
        </div>
        <div className="w_100_per display_flex flex_align_start flex_gap_xs">
          <div className="display_flex flex_center flex_gap_xxs w_20_per bg_neutral_300 m_y_md_mobile m_y_sm_desktop p_y_sm_desktop  p_y_xs_mobile br_xxs">
            <img
              src="https://flagcdn.com/w80/mx.png"
              alt="mx"
              className="w_40_per"
            />
            <Typography scale="xsmall" weight="600" className="">
              +52
            </Typography>
          </div>
          <div className="w_80_per">
            <Input
              title=""
              type="number"
              name={StepEnum.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              value={_.get(values, StepEnum.phone)}
              error={
                !!_.get(errors, StepEnum.phone) &&
                getFieldMeta(StepEnum.phone).touched
              }
              subtitle={
                getFieldMeta(StepEnum.phone).touched
                  ? _.get(errors, StepEnum.phone)
                  : undefined
              }
            />
          </div>
        </div>
      </div>

      <Input
        name={StepEnum.email}
        onBlur={handleBlur}
        onChange={handleChange}
        value={_.get(values, StepEnum.email)}
        title="Correo"
        icon={<Email />}
        type="text"
        error={
          !!_.get(errors, StepEnum.email) &&
          getFieldMeta(StepEnum.email).touched
        }
        subtitle={
          getFieldMeta(StepEnum.email).touched
            ? (_.get(errors, StepEnum.email))
            : undefined
        }
      />

      <Input
        type="text"
        title="CURP"
        name={StepEnum.curp}
        icon={<Curp className="custom-input-color" />}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.target.value = e.target.value.toUpperCase();
          handleChange(e);
        }}
        value={_.get(values, StepEnum.curp)}
        error={
          !!_.get(errors, StepEnum.curp) &&
          getFieldMeta(StepEnum.curp).touched
        }
        subtitle={
          getFieldMeta(StepEnum.curp).touched
            ? _.get(errors, StepEnum.curp)
            : undefined
        }
      />

      <Input
        type="text"
        title="¿Dónde vives?"
        name={StepEnum.address}
        icon={<Location className="custom-input-color" />}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.target.value = e.target.value.toUpperCase();
          handleChange(e);
        }}
        value={_.get(values, StepEnum.address)}
        error={
          !!_.get(errors, StepEnum.address) &&
          getFieldMeta(StepEnum.address).touched
        }
        subtitle={
          getFieldMeta(StepEnum.address).touched
            ? _.get(errors, StepEnum.address)
            : undefined
        }
      />
    </>
  );
};

export const Step2: FC = () => {
  const {
    values,
    setFieldValue,
  } = useFormikContext<StepFormValues>();
  const [phone, setPhone] = useState<string>(_.get(values, StepEnum.phone, ""));
  const [code, setCode] = useState<string[]>(["", "", "", ""]);
  const inputsRef = useRef<HTMLInputElement[]>([]);

  const handleCodeChange = (value: string, index: number) => {
    if (/^\d$/.test(value) || value === "") {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value && index < 3) {
        inputsRef.current[index + 1]?.focus();
      }
    }
  };

  const handleBackspace = (e: React.KeyboardEvent, index: number) => {
    if (e.key === "Backspace" && code[index] === "" && index > 0) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  useEffect(() => {
    if (code.join("").length === 4) setFieldValue(StepEnum.code, code.join(""));
  }, [code]);

  return (
    <>
      <Typography className="m_y_none m_b_md text_center" weight="400" scale="medium" scaleMobile="medium" component="p">
        Te enviamos un código a tu número de teléfono. Ingrésalo aquí para verificar tu identidad.
      </Typography>

      <div className="display_flex flex_gap_md w_200_px m_auto m_y_md">
        {code.map((digit, index) => (
          <input
            key={index}
            type="text"
            value={digit}
            maxLength={1}
            onChange={(e) => handleCodeChange(e.target.value, index)}
            onKeyDown={(e) => handleBackspace(e, index)}
            ref={(el) => {inputsRef.current[index] = el!;}}
            className="input-code"
          />
        ))}
      </div>

      <Typography className="m_y_none m_b_md text_center" weight="400" scale="medium" scaleMobile="medium" component="p">
        ¿<strong>+52{phone}</strong> No es tu número?
        <Button scale="small" variant="ghost" onClick={() => {}}>
          <Typography weight="600" scale="medium" scaleMobile="medium">
            Cámbialo ahora mismo.
          </Typography>
        </Button>
      </Typography>

      <Input
        name={StepEnum.authDataTreatment}
        checked={_.get(values, StepEnum.authDataTreatment)}
        onChange={(e) => {setFieldValue(StepEnum.authDataTreatment, e.target.checked);}}
        className=""
        title=""
        options={[
          {
            label: (
            <>
              He leído y acepto los
              <Link to="/terminos-y-condiciones" target="_blank">
                Términos y Condiciones.
              </Link>{" "}
              y autorizo el tratamiento de mis datos personales de acuerdo con la
              {" "}<Link to="/aviso-de-privacidad" target="_blank">
                Política de Privacidad.
              </Link>
            </>
            ),
            value: 1,
          },
        ]}
        type="checkbox"
      />
      <Input
        name="extra"
        checked={_.get(values, StepEnum.buroAcceptance)}
        onChange={(e) => {setFieldValue(StepEnum.buroAcceptance, e.target.checked);}}
        className="border_neutral_700 center_x w_fit"
        title=""
        options={[
          {
            label: (
            <>
              Entiendo y autorizo que mi información será utilizada para consultar mi historial crediticio
            </>
            ),
            value: 1,
          },
        ]}
        type="checkbox"
      />
    </>
  );
};

export const Step3: FC = () => {
  const {
    values,
    errors,
    setFieldValue,
  } = useFormikContext<StepFormValues>();

  const dependantsValues: {value: number; text: string }[] = [
    { value: 0, text: "0" },
    { value: 1, text: "1" },
    { value: 2, text: "2" },
    { value: 3, text: "3" },
    { value: 4, text: "4" },
    { value: 5, text: "5+" },
  ];

  const initialAssetsInfo = [
    { id: 0, text: AssetsEnum.NINGUNO, isActive: true, icon: NoneIcon },
    { id: 1, text: AssetsEnum.CASA, isActive: false, icon: HomeIcon },
    { id: 2, text: AssetsEnum.CARRO, isActive: false, icon: CarIcon },
    { id: 3, text: AssetsEnum.MOTO, isActive: false, icon: MotoIcon },
    { id: 4, text: AssetsEnum.OTRO, isActive: false, icon: CubeIcon },
  ];
  const [assetsInfo, setAssetsInfo] = useState(initialAssetsInfo);
  const [anotherAssets, setAnotherAssets] = useState("");
  const [isNoneSelected, setIsNoneSelected] = useState(true);
  const getOtherIsActiveValue = assetsInfo.slice(-1)[0].isActive;

  const currentAssets = assetsInfo
    .filter((asset) => asset.isActive)
    .map((item) => item.text);
  const findNoneAssets = currentAssets.find(
    (asset) => asset === AssetsEnum.NINGUNO
  );

  const handleAnotherAssets = (e: any) => {
    setAnotherAssets(e.target.value);
    setFieldValue(StepEnum.assets, [...currentAssets, e.target.value]);
  };

  useEffect(() => {
    if (currentAssets.length > 1 && isNoneSelected) {
      const currentValue = assetsInfo[0];
      const nextState = { ...currentValue, isActive: false };
      const removeNonOpt = assetsInfo.splice(1);
      const newArray = [nextState, ...removeNonOpt];
      setAssetsInfo(newArray);
    }
    if (currentAssets.length <= 0) {
      setAssetsInfo(initialAssetsInfo);
    }
  }, [currentAssets]);

  useEffect(() => {
    if (findNoneAssets) {
      setIsNoneSelected(true);
      setAssetsInfo(initialAssetsInfo);
    } else {
      setIsNoneSelected(false);
    }
  }, [findNoneAssets]);

  useEffect(() => {
    if (findNoneAssets === AssetsEnum.NINGUNO) {
      setAssetsInfo(initialAssetsInfo);
    }
  }, []);

  useEffect(() => {
    if (currentAssets.length > 0) {
      setFieldValue(StepEnum.assets, currentAssets);
    }
  }, [assetsInfo]);

  const livesWith: LivesWithEnum[] = [
    LivesWithEnum.alone,
    LivesWithEnum.spouse,
    LivesWithEnum.friends,
    LivesWithEnum.parents,
    LivesWithEnum.couple,
    LivesWithEnum.Siblings,
    LivesWithEnum.children,
  ];

  const [checkboxState, setCheckboxState] = React.useState<any>(() => {
    const state: any = {};
    livesWith.forEach((key) => {
      state[key] = (_.get(values, StepEnum.livesWith, []) as LivesWithEnum[]).includes(key);
    });
    return state;
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === LivesWithEnum.alone && event.target.checked) {
      setCheckboxState(() => {
        const state: any = {};
        livesWith.forEach((key: string) => {
          state[key] = false;
        });
        state[event.target.name]= event.target.checked;
        return state;
      });
    } else {
      setCheckboxState({
        ...checkboxState,
        [event.target.name]: event.target.checked,
        [LivesWithEnum.alone]: false,
      });
    }
  };

  useEffect(() => {
    const nextState = Object.keys(checkboxState).filter(
      (key) => checkboxState[key] === true
    );
    setFieldValue(StepEnum.livesWith, nextState);
  }, [checkboxState]);

  return (
    <>
      <Typography className="m_y_none m_b_md text_center" weight="400" scale="medium" scaleMobile="medium" component="p">
        Queremos conocerte mejor. Cuéntanos un poco sobre con quién vives, quiénes dependen de ti económicamente y qué activos tienes.
      </Typography>

      <div className="m_y_xl">
        <div className="dso_input_cont">
          <div className="dso_input_title m_b_none">
            <div className="dso_input_img">
              <Chat className="text_primary_300" />
            </div>
            <div className="dso_input_text">¿Cuántas personas dependen de ti?</div>
          </div>
        </div>
        <div className="w_100_per display_flex flex_align_start flex_gap_xs dependantsCount m_y_md">
          {dependantsValues.map(({ value, text}) => (
            <button
              className={`dependantsCount-card${_.get(values, StepEnum.dependantsCount, undefined) === value ? " dependantsCount-card--active" : ""}`}
              key={`dependants-${value}`}
              onClick={() => setFieldValue(StepEnum.dependantsCount, value)}
              type="button"
            >
              { text }
            </button>
          ))}
        </div>
      </div>

      <div className="m_y_xl">
        <div style={{ display: "flex", alignItems:"center" }}>
          <div className="m_r_xs ">
            <CabinHouse className="text_primary_300" />
          </div>
          <span className="text_small_600 text_neutral_700">¿Con quien vives?</span>
        </div>
        <CustomCheckBox
          options={livesWith}
          checkboxState={checkboxState}
          handleCheckboxChange={handleCheckboxChange}
        />
      </div>

      <div className="m_y_xl">
        <div style={{ display: "flex", alignItems:"center" }}>
          <div className="m_r_xs ">
            <Building className="text_primary_300" />
          </div>
          <span className="text_small_600 text_neutral_700">¿Con qué activo cuentas?</span>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {assetsInfo.map((item, idx) => (
            <AssetCard
              key={idx}
              item={item}
              assetsInfo={assetsInfo}
              setAssetsInfo={setAssetsInfo}
            />
          ))}
          {getOtherIsActiveValue && (
            <Input
              type="text"
              title="¿Con qué OTRO activo cuentas?"
              name="anotherAssets"
              onChange={handleAnotherAssets}
              onBlur={handleAnotherAssets}
              placeholder="Cuentanos que otro activo posees"
              icon={<Boy className="primary_300" />}
              value={anotherAssets}
            />
          )}
        </div>
      </div>

    </>
  );
};

export const Step4: FC = () => {
  const {
    values,
    handleChange,
    errors,
    handleBlur,
    getFieldMeta,
    setFieldValue,
  } = useFormikContext<StepFormValues>();
  const [workInDigitalPlatforms, setWorkInDigitalPlatforms] = useState<boolean>(true);

  useEffect(() => {
    setFieldValue(StepEnum.workInDigitalPlatforms, workInDigitalPlatforms);

    if (workInDigitalPlatforms) {
      setFieldValue(StepEnum.economicActivity, EconomicActivityEnum.conductorRepartidor);
    } else {
      setFieldValue(StepEnum.economicActivity, undefined);
      setFieldValue(StepEnum.otherEconomicActivity, undefined);
    }
  }, [workInDigitalPlatforms]);

  const activityOptions = [
    { label: "Empleado", value: EconomicActivityEnum.empleado },
    { label: "Repartidor", value: EconomicActivityEnum.repartidor },
    { label: "Comerciante", value: EconomicActivityEnum.comerciante },
    { label: "Conductor", value: EconomicActivityEnum.conductor },
    { label: "Ayudante general", value: EconomicActivityEnum.ayudanteGeneral },
    { label: "Seguridad", value: EconomicActivityEnum.seguridad },
    { label: "Independiente", value: EconomicActivityEnum.independiente },
    { label: "Estudiante", value: EconomicActivityEnum.estudiante },
    { label: "Policía", value: EconomicActivityEnum.policia },
    { label: "Servidor público", value: EconomicActivityEnum.servidorPublico },
    { label: "Enfermero", value: EconomicActivityEnum.enfermero },
    { label: "Mecánico", value: EconomicActivityEnum.mecanico },
    { label: "Otro", value: EconomicActivityEnum.otro },
  ];

  return (
    <>
      <Typography className="m_y_none m_b_md text_center" weight="400" scale="medium" scaleMobile="medium" component="p">
        Por favor, indícanos si actualmente trabajas como repartidor en Rappi, Didi o Uber.
      </Typography>

      <div style={{"maxWidth": "100px"}} className="m_auto m_y_md workInDigitalPlatforms">
        <Typography
          className="w_100_per cursor_pointer p_x_md p_y_xs dso_select_list_label display_flex flex_align_center flex_gap_md"
          scale="small"
          color="neutral_600"
          weight="400"
          component="label"
        >
          <input
            type="radio"
            className="m_none"
            name={StepEnum.workInDigitalPlatforms}
            onChange={() => setWorkInDigitalPlatforms(true)}
            checked={workInDigitalPlatforms}
          />
          Sí
        </Typography>
        <Typography
          className="w_100_per cursor_pointer p_x_md p_y_xs dso_select_list_label display_flex flex_align_center flex_gap_md"
          scale="small"
          color="neutral_600"
          weight="400"
          component="label"
        >
          <input
            type="radio"
            className="m_none"
            name={StepEnum.workInDigitalPlatforms}
            onChange={() => setWorkInDigitalPlatforms(false)}
            checked={!workInDigitalPlatforms}
          />
          No
        </Typography>
      </div>

      {!workInDigitalPlatforms && <div className="dso_input_cont m_y_md">
        <div className="dso_input_title">
          <div className="dso_input_img">
            <Groups className="custom-input-color" />
          </div>
          <div className="dso_input_text">¿A qué te dedicas?</div>
        </div>
        <FormControl sx={{ minWidth: "100%" }} size="small">
          <OzonSelect
            fieldName={StepEnum.economicActivity}
            options={activityOptions}
            handleBlur={handleBlur}
            handleChange={handleChange}
            values={values}
            getFieldMeta={getFieldMeta}
            errors={errors}
          />
        </FormControl>
      </div>}

      {_.get(values, StepEnum.economicActivity) === EconomicActivityEnum.otro && <Input
        type="text"
        title="Describe tu ocupación"
        name={StepEnum.otherEconomicActivity}
        onChange={handleChange}
        onBlur={handleBlur}
        icon={<Groups className="primary_300" />}
        value={_.get(values, StepEnum.otherEconomicActivity)}
        error={
          !!_.get(errors, StepEnum.otherEconomicActivity) &&
          getFieldMeta(StepEnum.otherEconomicActivity).touched
        }
        subtitle={
          getFieldMeta(StepEnum.otherEconomicActivity).touched
            ? (_.get(
                errors,
                StepEnum.otherEconomicActivity
                ,"") as string)
            : undefined
        }
      />}
    </>
  );
};

export const Step5: FC = () => {
  const {
    errors,
    handleBlur,
    getFieldMeta,
    setFieldValue,
  } = useFormikContext<StepFormValues>();
  const [user, setUser] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [platform, setPlatform] = useState<string>("");

  useEffect(() => {
    if (user.length && password.length && platform.length) {
      setFieldValue(StepEnum.palenca, true);
    }
  }, [user, password, platform]);

  return (
    <>
      <Input
        type="text"
        title="Usuario"
        className="palenca_input"
        name={StepEnum.otherEconomicActivity}
        onChange={(e) => setUser(e.target.value)}
        onBlur={handleBlur}
        value={user}
      />
      <Input
        title="Contraseña"
        name="password"
        type="password"
        className="palenca_input"
        onBlur={handleBlur}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <div className="dso_input_cont palenca_input m_y_md">
        <div className="dso_input_title">
          <div className="dso_input_text">Plataforma</div>
        </div>
        <FormControl sx={{ minWidth: "100%" }} size="small">
          <OzonSelect
            fieldName="platform"
            options={[
              { label: "Rappi", value: "Rappi" },
              { label: "Didi", value: "Didi" },
              { label: "Uber", value: "Uber" }
            ]}
            handleBlur={handleBlur}
            handleChange={(e: any) => setPlatform(e.target.value)}
            values={{ "platform": platform }}
            getFieldMeta={getFieldMeta}
            errors={errors}
          />
        </FormControl>
      </div>
    </>
  );
};

export const Step6: FC = () => {
  const {
    values,
    setFieldValue,
  } = useFormikContext<StepFormValues>();

  return (
    <>
      <Typography className="m_y_none m_b_md text_center" weight="400" scale="medium" scaleMobile="medium" component="p">
        Esta información nos ayuda a entender mejor tu situación financiera. Por favor, indícanos si puedes comprobar los ingresos que mencionas.
      </Typography>
      <div style={{"maxWidth": "100px"}} className="m_auto m_y_md checksIncomes">
        <Typography
          className="w_100_per cursor_pointer p_x_md p_y_xs dso_select_list_label display_flex flex_align_center flex_gap_md"
          scale="small"
          color="neutral_600"
          weight="400"
          component="label"
        >
          <input
            type="radio"
            className="m_none"
            name={StepEnum.checksIncomes}
            onChange={() => setFieldValue(StepEnum.checksIncomes, true)}
            checked={_.get(values, StepEnum.checksIncomes)}
          />
          Sí
        </Typography>
        <Typography
          className="w_100_per cursor_pointer p_x_md p_y_xs dso_select_list_label display_flex flex_align_center flex_gap_md"
          scale="small"
          color="neutral_600"
          weight="400"
          component="label"
        >
          <input
            type="radio"
            className="m_none"
            name={StepEnum.checksIncomes}
            onChange={() => setFieldValue(StepEnum.checksIncomes, false)}
            checked={!_.get(values, StepEnum.checksIncomes)}
          />
          No
        </Typography>
      </div>
    </>
  );
};

export const Step6Complement: FC = () => {
  return (
    <div className="m_y_xxl">
      <Typography className="m_y_none m_b_md text_center" weight="400" scale="medium" scaleMobile="medium" component="p">
        Pueden ser:
      </Typography>
      <div className="border_solid border_neutral_700 border_0 border_t_1 p_t_xxl checksIncomes display_flex">
        <div className="checksIncomes-card">
          <Note className="checksIncomes-icon" />
          <Typography className="m_y_none m_b_md text_center" weight="400" scale="small" scaleMobile="small" component="p">
            Recibos de nómina
          </Typography>
        </div>
        <div className="checksIncomes-card">
          <Mobile className="checksIncomes-icon" />
          <Typography className="m_y_none m_b_md text_center" weight="400" scale="small" scaleMobile="small" component="p">
            Afiliación a Uber, Didi o Rappi
          </Typography>
        </div>
        <div className="checksIncomes-card">
          <Bill className="checksIncomes-icon" />
          <Typography className="m_y_none m_b_md text_center" weight="400" scale="small" scaleMobile="small" component="p">
            Estados de cuenta
          </Typography>
        </div>
      </div>
    </div>
  );
};
