import { PandaboardApiInstance } from "helpers/api";
import { FormAddDocumentRequestDelete, FormAddDocumentRequestPut, FormAddReferenceRequest, IFinancialForm } from "models/financialForm/interfaces/financialForm.interface";

export const getFinancialForm = async (id: string): Promise<IFinancialForm> =>
  PandaboardApiInstance
    .get(`financial-form/${id}`, { requireAuth: true })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchUpdateFinancialForm = async (formId: string, payload: any) =>
  PandaboardApiInstance
    .put(`financial-form/WP/${formId}`, payload, { requireAuth: true })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchFormCrediticioUpdatePalenca = async () =>
  PandaboardApiInstance.put("financial-form/palenca", {}, { requireAuth: true })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchFormCrediticioAddDocument = async ({
  formId,
  formData,
  documentName,
}: FormAddDocumentRequestPut) =>
  PandaboardApiInstance.put(
    `financial-form/${formId}/documentWP/${documentName}`,
    formData,
    {
      requireAuth: true,
    }
  )
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchDeleteFormCrediticioAddDocument = async ({
  formId,
  documentId,
}: FormAddDocumentRequestDelete) =>
  PandaboardApiInstance.delete(`financial-form/${formId}/documentWP/${documentId}`, {
    requireAuth: true,
  })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchFormCrediticioAddReference = async ({
  loanFormId,
  references,
}: FormAddReferenceRequest) =>
  PandaboardApiInstance.put(
    `financial-form/${loanFormId}/addReference`,
    references,
    { requireAuth: true }
  )
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchFinancilFormAlertRetry = async (id: string) =>
  PandaboardApiInstance.post(
    `financial-form/${id}/alertRetry`,
    {},
    { requireAuth: true }
  )
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const fetchNotifyValidateIdentity = async (
  formId: string,
  internalId?: string
) =>
  PandaboardApiInstance.post(
    "financial-form/WP/validate-identity",
    { formId, internalId },
    { requireAuth: true }
  )
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });
