import React, { useEffect, useState } from "react";
import _ from "lodash";
import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useFormikContext } from "formik";
import { Typography } from "design_system/src";

import { LeadFormFieldsEnum } from "models/lead/enums/lead.enums";
import { unformatPhone } from "helpers/phone";
import { IStepperFormValues } from "../../context/context";

interface Ubi {
  latitude: number;
  longitude: number;
  zoom: number;
}

export const ModalConfirmPhoneNumber = () => {
  const { values, validateForm, setFieldValue } =
    useFormikContext<IStepperFormValues>();
  const [dialCode, setDialCode] = useState<number>(52);
  const [formattedPhone, setFormattedPhone] = useState<string>("");

  useEffect(() => {
    const phone: string = _.get(values, LeadFormFieldsEnum.PHONE)!;
    setDialCode(phone.startsWith("52") ? 52 : 57);
    setFormattedPhone(unformatPhone(phone));
  }, [values]);

  return (
    <div className="display_flex flex_col">
      <Typography
        scale="medium"
        weight="400"
        textColor="neutral_900"
        className="text_center"
      >
        ¿Confirmas que tu número telefónico es <strong>+{dialCode} {formattedPhone}</strong>?
      </Typography>
    </div>
  );
};
