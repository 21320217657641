/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { Dispatch, useState, ReactElement, useEffect, useMemo, useRef } from "react";
import { useSelectedLoanDuration, useSelectedVehicle } from "hooks/useVehicleSelection";
import { LeadCreatedOnEnum, LeadStatusEnum, LeadStepEnum } from "models/lead/enums/lead.enums";
import { Lead } from "models/lead/interfaces/lead.interface";
import { getQueryParams, getUtmObject } from "helpers/queryParams";
import { EconomicActivityEnum } from "models/shared/enums/shared.enums";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { getLead } from "models/lead/controllers/lead.controller";
import { PrivacyPolicyAcceptance, TermsOfServiceAcceptance } from "models/shared/interfaces/userAcceptance.interface";
import { moffin } from "models/moffin/interfaces/moffin.interface";

export interface IStepperItem {
    component: ReactElement<any, any>;
    validationKey?: string;
    stepTitle?: string;
    functionStep?: (values: any) => any;
    stepModalConfig?: {
        widthDesktop?: number;
        heightDesktop?: number;
        icon: React.ReactElement<any, any> | null;
        handleOpen?: any;
        handleClose: any;
        title: JSX.Element;
        subtitle: JSX.Element;
    };
}

export interface IStepperFormValues {
    [LeadStepEnum.emailStep]:{
        email: string;
        termsOfServiceAcceptance: TermsOfServiceAcceptance;
        privacyPolicyAcceptance: PrivacyPolicyAcceptance;
    },
    [LeadStepEnum.personalInformationStep] : {
        name?: string;
        lastName?: string;
        phone?: string;
        curp?: string;
    },
    [LeadStepEnum.addressStep] : {
        fullAddress?: {
            zipCode: string;
            state: string;
            city: string;
            delegation: string;
            neighborhood: string;
            street: string;
            intNumber?: number;
            extNumber?: number;
            lat?: number;
            long?: number;
        }
    },
    [LeadStepEnum.phoneStep] : {
        phone?: string;
    },
    [LeadStepEnum.phoneValidationStep] : {
        moffin?: moffin;
    },
    [LeadStepEnum.financialInformationStep] : {
        monthlyIncome?: number;
        monthlyOutcome?: number;
    },
    [LeadStepEnum.dependantsStep] : {
        dependantsCount?: number;
        childrenCount?: number;
    },
    [LeadStepEnum.employmentAndEducationStep] : {
        workInDigitalPlatforms?: boolean;
        economicActivity?: {
            value: string;
            description?: string;
        };
        companyName?: string;
        educationalLevel?: string;
    },
    [LeadStepEnum.civilStatusStep] : {
        civilStatus?: string;
        livesWith?: string[];
    },
    [LeadStepEnum.assetsStep] : {
        assets?: string[];
    },
};

export const initLead: Lead = {
    status: LeadStatusEnum.inProgress,
    email: "",
    name: undefined,
    lastName: undefined,
    curp: undefined,
    city: undefined,
    vehicle: undefined,
    advancedMoney: undefined,
    loanDuration: undefined,
    personalInformation: undefined,
    financialInformation: undefined,
    termsOfServiceAcceptance: undefined as unknown as TermsOfServiceAcceptance,
    privacyPolicyAcceptance: undefined as unknown as PrivacyPolicyAcceptance,
    step: LeadStepEnum.emailStep,
    createdOn: LeadCreatedOnEnum.webpage,
};

export const setNextStep = (step: LeadStepEnum) => {
    const steps = [
        LeadStepEnum.emailStep,
        LeadStepEnum.personalInformationStep,
        LeadStepEnum.addressStep,
        LeadStepEnum.phoneStep,
        LeadStepEnum.phoneValidationStep,
        LeadStepEnum.financialInformationStep,
        LeadStepEnum.dependantsStep,
        LeadStepEnum.employmentAndEducationStep,
        LeadStepEnum.civilStatusStep,
        LeadStepEnum.assetsStep,
    ];
    const index = steps.indexOf(step);
    return steps[index + 1];
};

interface FormApplicationContextType {
    loading: boolean;
    step: LeadStepEnum;
    lead: Lead;
    setLead: Dispatch<React.SetStateAction<Lead>>;
    cleanLead: () => void;
}

const FormApplicationContext = React.createContext<FormApplicationContextType>({
    loading: true,
    step: LeadStepEnum.emailStep,
    lead: initLead,
    setLead: () => {},
    cleanLead: () => {}
});

const FormApplicationProvider: React.FC = ({ children }) => {
    const selectedLoanDuration = useSelectedLoanDuration();
    const selectedVehicle = useSelectedVehicle();
    const { alliedCompany } = getQueryParams(window.location.search);
    const { city } = useSelector((state: RootState) => state.cityReducer);

    const [lead, setLead] = useState<Lead>(initLead);
    const [loading, setLoading] = useState<boolean>(true);
    const leadRef = useRef(lead);
    const [step, setStep] = useState<LeadStepEnum>(LeadStepEnum.emailStep);

    useEffect(() => {
        leadRef.current = lead;
    }, [lead]);

    const cleanLead = () => {
        localStorage.removeItem("lead");
    };

    useEffect(() => {
        const fetchData = async () => {
            const saveLead = localStorage.getItem("lead");
            let response;

            response = saveLead ? JSON.parse(saveLead) : initLead;
            if (response._id) {
                response = await getLead(response._id);
                if (response.status !== LeadStatusEnum.inProgress ) {
                    response = initLead;
                }
            }
            if (selectedLoanDuration) {
                response.loanDuration = selectedLoanDuration;
            }
            if (selectedVehicle) {
                response.vehicle = selectedVehicle._id;
                response.advancedMoney = selectedVehicle.advancedMoney;
            }
            if (alliedCompany) {
                response.financialInformation!.workInDigitalPlatforms = true;
                response.financialInformation!.economicActivity!.value = EconomicActivityEnum.conductorRepartidor;
                response.financialInformation!.companyName = alliedCompany;
            }
            if (city) {
                response.city = city;
            }

            if (response.status === LeadStatusEnum.completed) {
                response.status = LeadStatusEnum.inProgress;
            }

            const utm = getUtmObject();
            if (utm) {
                response.utm = utm;
            }

            setLead(response);
            setLoading(false);
        };

        fetchData();

        return () => {
            cleanLead();
        };
    }, []);

    useEffect(() => {
        setStep(lead.step ?? LeadStepEnum.emailStep);
        localStorage.setItem("lead", JSON.stringify(lead));
    }, [lead]);

    const contextValue = useMemo(
        () => ({ loading, step, lead, setLead, cleanLead }),
        [step, lead, setLead, cleanLead]
    );

    return (
        <FormApplicationContext.Provider value={contextValue}>
            {children}
        </FormApplicationContext.Provider>
    );
};

export { FormApplicationContext, FormApplicationProvider };
