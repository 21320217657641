import { Lead, LeadCreate, LeadResponse, LeadUpdate } from "../interfaces/lead.interface";

export const toLead = (leadResponse: LeadResponse): Lead => {
    const {
        _id,
        status,
        email,
        name,
        lastName,
        curp,
        city,
        createdOn,
        vehicle,
        advancedMoney,
        loanDuration,
        personalInformation,
        financialInformation,
        termsOfServiceAcceptance,
        privacyPolicyAcceptance,
        moffin,
        step
    } = leadResponse;
    return {
        _id,
        status,
        email,
        name,
        lastName,
        curp,
        city,
        createdOn,
        vehicle,
        advancedMoney,
        loanDuration,
        personalInformation,
        financialInformation,
        termsOfServiceAcceptance,
        privacyPolicyAcceptance,
        moffin,
        step
    };
};

export const toLeadCreate = (lead: Lead): LeadCreate => {
    const {
        email,
        termsOfServiceAcceptance,
        privacyPolicyAcceptance,
        vehicle,
        advancedMoney,
        loanDuration,
        utm,
    } = lead;
    return {
        email,
        termsOfServiceAcceptance,
        privacyPolicyAcceptance,
        vehicle,
        advancedMoney,
        loanDuration,
        utm,
    };
};

export const toLeadUpdate = (lead: Lead): LeadUpdate => {
    const {
        status,
        email,
        name,
        lastName,
        curp,
        city,
        vehicle,
        advancedMoney,
        loanDuration,
        personalInformation,
        financialInformation,
        step
    } = lead;
    return {
        status,
        email,
        name,
        lastName,
        curp,
        city,
        vehicle,
        advancedMoney,
        loanDuration,
        personalInformation,
        financialInformation,
        step
    };
};
