import Swal from "sweetalert2";
import { PandaboardApiInstance } from "helpers/api";

const baseUrl = "moffin";

export const createForm = async (leadId: string): Promise<any> =>
  PandaboardApiInstance
    .post(`${baseUrl}/form/${leadId}`)
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const sendSms = async (moffinId: string): Promise<any> =>
  PandaboardApiInstance
    .post(`${baseUrl}/form/sms/send-nip/${moffinId}`)
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const verifyNip = async (moffinId: string, nip: string): Promise<any> =>
  PandaboardApiInstance
    .post(`${baseUrl}/form/sms/verify-nip/${moffinId}`, { nip })
    .then((res) => ({ validated: true }))
    .catch((err: any) => {
      if (err.response && err.response.status === 401) {
        Swal.fire({
          text: "Por favor, vuelve a intentarlo.",
          title: "¡Ups! Código incorrecto",
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "bg_primary_300 w_90_per",
            title: "text_neutral_800",
            container: "text_neutral_700",
          },
        });
      }
      return { validated: false };
    });

export const submitForm = async (moffinId: string, nip: string): Promise<any> =>
  PandaboardApiInstance
    .post(`${baseUrl}/form/submit/${moffinId}`, { nip })
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });

export const getServiceQueries = async (moffinId: string, nip: string): Promise<any> =>
  PandaboardApiInstance
    .get(`${baseUrl}/form/result/${moffinId}`)
    .then((res) => res.data)
    .catch((err: any) => {
      throw err.response.data;
    });
