import axios from "axios";

const statusName: {[key: string]: string} = {
  "01": "AGU",
  "02": "BCN",
  "03": "BCS",
  "04": "CAM",
  "05": "COA",
  "06": "COL",
  "07": "CHP",
  "08": "CHH",
  "09": "CMX",
  "10": "DUR",
  "11": "GUA",
  "12": "GRO",
  "13": "HID",
  "14": "JAL",
  "15": "MEX",
  "16": "MIC",
  "17": "MOR",
  "18": "NAY",
  "19": "NLE",
  "20": "OAX",
  "21": "PUE",
  "22": "QUE",
  "23": "ROO",
  "24": "SLP",
  "25": "SIN",
  "26": "SON",
  "27": "TAB",
  "28": "TAM",
  "29": "TLA",
  "30": "VER",
  "31": "YUC",
  "32": "ZAC"
};

type CpResponse = {
  postalCode: string;
  neighborhood: string;
  neighborhoodType: string;
  municipality: string;
  state: string;
  city: string;
  stateCode: string;
  officePostalCode: string;
  neighborhoodTypeCode: string;
  municipalityCode: string;
  neighborhoodId: string;
  zone: string;
  cityCode: string;
};

type FetchResponse = CpResponse[];

type FullAdressResponse = {
  zipCode: string;
  state: string;
  stateCode: string;
  city: string;
  delegation: string;
  neighborhoods: string[];
};

export const fetchByPostalCode = async (cp: string): Promise<FullAdressResponse> => {
  try {
    const config = axios.create({
      baseURL: process.env.REACT_APP_MOFFIN_BASE_URL,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${process.env.REACT_APP_MOFFIN_AUTH_TOKEN}`,
      },
    });

    const { data } = await config.get<FetchResponse>(`api/v1/postal-codes/${cp}`);

    const neighborhoods: string[] = data.map(({ neighborhood }) => neighborhood);
    return {
      zipCode: data[0].postalCode,
      state: data[0].state,
      stateCode: statusName[data[0].stateCode],
      city: data[0].city,
      delegation: data[0].municipality,
      neighborhoods
    };
  } catch (err: any) {
    throw err.response.data;
  };
};
